import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from '../clients/clients.service';
import { ClientAddModel, ClientViewModel, GroupVerifiedStatus } from '../shared/models/client.model';
import { Assets } from '../shared/models/assets.model';
import { ClentProfileResponse, AppointmentResponse, WebClientResponse, UserDocumentResponse, FactFinderCategoriesResponse,FactFinderResponse, DocumentUrlResponse, FactFinderNewResponse, UserVisibilityResponse, NewsFeedResponse, NewsFeedResponseProfile, clientMigrateResponse, clientRevertResponse, UserDocumentReorderResponse } from '../shared/models/response.model';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { AddAppointmentComponent } from '../appointments/add-appointment/add-appointment.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppointmentAddModel } from '../shared/models/appointment.model';
import { AppointmentService } from '../appointments/appointment.service';
import { CallComponent } from '../video/call/call.component';
import { EditAppointmentComponent } from '../appointments/edit-appointment/edit-appointment.component';
import { UserDocumentsService } from '../user-documents/user-documents.service';
import { UserDocumentsAddModel } from '../shared/models/user-documents.model';
import { AddUserDocumentsComponent } from '../user-documents/add-user-documents/add-user-documents.component';
import { InvoiceAddModel } from '../shared/models/invoice.model';
import { AddInvoiceComponent } from '../invoices/add-invoice/add-invoice.component';
import { FactFinderCategoriesView,FactFinder, FactFinderNew } from '../shared/models/fact-finder-categories.model';
import { FactFinderService } from '../fact-finder/fact-finder.service';
import { ToastrService } from 'ngx-toastr';
import { ViewFactComponent } from './view-fact/view-fact.component';
import { AuthService } from '../auth/auth.service';
import { AppConstants } from '../utility/app-constants';
import { CookieService } from 'ngx-cookie-service';
import { AddPrimaryMobileComponent } from './add-primary-mobile/add-primary-mobile.component';
import { AddUserNameComponent } from './add-user-name/add-user-name.component';
import { DatePipe } from '@angular/common';
// import { saveAs } from '@progress/kendo-drawing/dist/npm/pdf';
import {saveAs} from 'file-saver';
import { NewsFeedService } from '../news-feed/news-feed.service';
import { NewsFeedAddModel } from '../shared/models/news-feed.model';
import { ViewNewsFeedComponent } from '../news-feed/view-news-feed/view-news-feed.component';
import { NewServiceAgreementComponent } from './new-service-agreement/new-service-agreement.component';
import { UserHistoryComponent } from './user-history/user-history.component';
import { FamilyTreePdfComponent } from './family-tree/family-tree-pdf/family-tree-pdf.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { log } from 'console';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild('serviceAgreement') SASectionRef: ElementRef

  clientId: string;
  clientClientId: string;
  client:ClientViewModel;
  isLoaded : boolean = false;
  isLoaded1 : boolean = false;
  isLoaded2 : boolean = false;
  isLoaded3 : boolean = false;
  isLoaded4 : boolean = false;
  taxableIncomeVal : any = [];
  taxableIncomeYear : any = [];
  groupByBrokeragebyYear : any = {};
  groupArr : any;
  groupArr2 : any;
  result : any;
  result2 : any;
  showInvoice :boolean = true;
  showFactFinderCat :boolean = true;
  dtOptions: DataTables.Settings = {};
  assetList : any;
  liabilityList : any;
  total_annual_premiums : any = 0;
  sumInsured : any = [];
  clientCode : any;
  //assetModel : Assets = new Assets();
  isStaffMember : boolean;
  isVisibleToStaff : boolean;
  isVisibleDocument : boolean;
  showStaffMembers: boolean;
  factFinderUrl: String;
  familyTreeUrl: String;
  moneysoftUrl: String;
  isActive: boolean;
  companyCode:any;
  clientName:string = '';

  netWorthReportDetailURL:string;
  insuranceReportURL:string;
  netWorthSummaryDocumentURL:string;
  netWorthReporttDetailWithChartsURL:string;
  base64textString = [];
  isLoading:boolean = false;
  viewNewsFeed:boolean;
  moneysoftId:string;
  moneysoftLink:String;
  public loaded: boolean;
  public isMigrated: boolean;
  public lastName: string;
  isVerified: boolean;

  constructor(
    private factFinderService: FactFinderService,
    private userDocumentService: UserDocumentsService,
    private router : Router ,
    private route: ActivatedRoute,
    private clientsService: ClientsService,
    private modalService: BsModalService,
    private appointmentService: AppointmentService,
    private authService: AuthService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    public datepipe: DatePipe,
    private http:HttpClient,
    private newsFeedService: NewsFeedService,
    private ngxService: NgxUiLoaderService
    ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.showStaffMembers = this.viewPortalPermission("View_Staff_Clients");
    this.viewNewsFeed = this.viewPortalPermission('View_News_Feed');  //View_Staff_Clients
    this.factFinderUrl = AppConstants.getFactFinderURL();
    this.familyTreeUrl = AppConstants.getFamilyTreeURL();
    this.moneysoftUrl = AppConstants.getMoneysoftURL();
    }

  modalRef :BsModalRef;
  appointmentList:AppointmentAddModel[];
  //pendingAppointmentList:AppointmentAddModel[];
  pendingAppointmentOne:AppointmentAddModel;
  userDocument:UserDocumentsAddModel;
  showTab: string = "Insurance";
  webClient: any;
  xeppoCode: any;
  userDocumentList:UserDocumentsAddModel[];
  factFinderCategoriesView:FactFinderCategoriesView[];
  factFinders:FactFinder[];
  factFindersNew:FactFinderNew[];
  factFindersFiltered:FactFinder[];
  factTitle: string = "";
  factFinderCategoryId : string;
  document_id : string;
  message;
  facGoalShort : any;
  facGoalMedium : any;
  facGoalLong : any;
  facDependent : any;
  factExpenses : any;
  facEntity : any;
  facInsurance : any;
  newsFeedList:NewsFeedAddModel[];
  xeppoGroupCode:any ="";
  loginHistoryData:any ='';
  loginHistoryDataLength:number = 0;
  business_id:string;


  ngOnInit() {
    this.clientId = this.route.snapshot.queryParams['id'];
    this.clientClientId =  this.route.snapshot.queryParams['clientId'];
    this.companyCode = this.cookieService.get('companyCode');
    this.business_id = JSON.parse(localStorage.getItem('businessId'));
    this.getNewsFeedList();
    this.GetClient();
    this.getAppointmentListCurrentUser(this.clientId); // comment by thilina, request by DevT (uncomment, DevT remove the service)
    this.userDocuments(this.clientId);
    // this.getNetWorthReportDetailDocument();
    // this.getInsuranceReportDocument();
    // this.getNWReportSummaryDocument();
    // this.getNWReporttDetailWithChartsDocument();
    //this.getFactFinderNewList();
    //this.getFactFinderCategories();
    this.getLoginHistory();

    this.dtOptions = {
      //pagingType: 'full_numbers',
      // pageLength: 10,
      // processing: true,
      paging: false,
      info: false,
      order:[]
    };
  }

  getMigrateStatus() {
    this.clientsService.getMigrateStatus(this.xeppoCode, this.companyCode).subscribe((res : any)=>{
      if (res.success) {
        this.isMigrated = res.response;
        this.setMoneysoftLink(res.response);
      }
    }, error=> {
    })
  }

  GetClient(){
    this.clientsService.getClient(this.clientId).subscribe((clientData:ClentProfileResponse)=>{

      this.client = clientData.response;
      if(this.client) {
        this.xeppoCode = this.client.client.xeppoCode;
        this.xeppoGroupCode = this.client.client.xeppoGroupCode;
        this.moneysoftId = this.client.moneysoftId;
        this.getMigrateStatus();
        // if (this.moneysoftId != null) {
        //   this.moneysoftUrl = `${AppConstants.getMoneysoftURL()}?msUserId=${this.moneysoftId}`;
        // } else {
        //   this.moneysoftUrl = `${AppConstants.getMoneysoftURL()}/register?first_name=${this.client.client.firstName}&last_name=${this.client.client.lastName}&email=${this.client.client.preferredEmail}&userId=${this.client.id}&xeppoCode=${this.xeppoCode}`;
        // }

        if(!this.clientClientId) {
          this.clientClientId = this.client.client.id;
        }

        if(this.client.clientCode){
          this.clientCode = this.client.clientCode;
        }

        if(this.client.client.lastName){
          this.lastName = this.client.client.lastName;
        }

        if(this.client && this.client.client ) {
          this.isVisibleToStaff= this.client.client.isVisibleToStaff;
          // this.isVisibleDocument= this.client.client.isVisibleDocument;
          this.isActive = this.client.isActive;

          if(!this.isVisibleToStaff && !this.showStaffMembers ){
            this.router.navigate(['clients']);
          }

          // if(!this.isVisibleDocument && !this.showStaffMembers ){
          //   this.router.navigate(['clients']);
          // }
        }

        if(this.client && this.client.client ){
          this.isStaffMember= this.client.client.isStaffMember;
          this.isVerified = this.client.client.groupVerifiedStatus === GroupVerifiedStatus.VERIFIED;
        }

        this.GetWebClient(this.xeppoCode);
        //this.gender = this.GetPatientGender.GetGender(this.patient.Gender);
        this.getDashboardData(this.clientId);
        }

        this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);

      });
  }

  setMoneysoftLink(isMigrated) {
    if(!isMigrated) {
      if(this.moneysoftId) {
        this.moneysoftLink = this.moneysoftUrl+"?msUserId="+this.moneysoftId+"&first_name="+this.client.client.firstName+"&last_name="+this.client.client.lastName+"&xeppoCode="+this.xeppoCode+"&isMigrated="+this.isMigrated+ "&id="+ this.client.id;
      } else {
        this.moneysoftLink = this.moneysoftUrl+"/register?first_name="+this.client.client.firstName+"&last_name="+this.client.client.lastName+"&email="+this.client.client.preferredEmail+"&userId="+this.client.id+"&xeppoCode="+this.xeppoCode+"&isMigrated="+this.isMigrated+ "&id="+ this.client.id;;
      }
    } else {
      this.moneysoftLink = this.moneysoftUrl+"/net-worth?&xeppoCode="+this.xeppoCode+"&isMigrated="+this.isMigrated +"&first_name="+this.client.client.firstName+"&last_name="+this.client.client.lastName+ "&id="+ this.client.id;;
    }
  }

  userDocuments(clientId){
    this.userDocumentService.userDocuments(clientId).subscribe((data:UserDocumentResponse)=>{
      this.userDocumentList = data.response;
      console.log('User document list  ---------');
      console.log(this.userDocumentList);
      this.isLoaded2 = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  viewDocumentSharepoint(userDocument) {
    // this.userDocumentService.getDocumentUrl(userDocument).subscribe((data:DocumentUrlResponse)=>{
    // let documentUrl = data.response;
    // console.log("doc url - >",documentUrl)
    //console.log('document url ---------');
    //console.log(documentUrl);
    // window.open(userDocument.documentURL, "_blank");

    // if (userDocument.documentURL.includes('_layouts')) {

    //   window.open(userDocument.documentURL, "_blank");
    // } else {

      this.userDocumentService.ViewDocument(userDocument.documentURL).subscribe((respons: any) => {
        if (respons.success) {
          window.open(respons?.response?.link, "_blank");
        }
      },
        (err: HttpErrorResponse) => {
          //console.log("Error"+ err.message);
        });
    // }


  }

  viewDocumentS3(userDocument){
    this.userDocumentService.getDocumentUrl(userDocument).subscribe((data:DocumentUrlResponse)=>{
    let documentUrl = data.response;
    window.open(documentUrl, "_blank");
    });
  }

  getDashboardData(ClientId:string){

  }

  refreshList(){
    this.userDocuments(this.clientId);
  }

  openAppointmentModal(){
    this.pendingAppointmentOne = new AppointmentAddModel();
    this.pendingAppointmentOne.clientId = this.clientId;
    this.pendingAppointmentOne.client = this.client;

    this.modalRef = this.modalService.show(AddAppointmentComponent,  {
      initialState: {
        title: 'Add Appointment',
        data: {
          appointment:this.pendingAppointmentOne
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog modal-lg modal_lg_width"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getAppointmentListCurrentUser(this.clientId);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['client/view'], { queryParams: { id: this.clientId, clientId: this.clientClientId }});

      }

    });
  }

  getAppointmentListCurrentUser(clientId){
      this.appointmentService.appointmentListCurrentUser(clientId).subscribe((data:AppointmentResponse)=>{
        this.appointmentList = data.response;

        // .filter(function(appointment){
        //   return appointment.sheduledDate == new Date(appointment.sheduledDate+'Z');;
        // });;

        //console.log('Appointment list for current user ---------');
        //console.log(this.appointmentList);
        //this.isLoaded = true;
        },
        (err: HttpErrorResponse)=>{
          //console.log("Error"+ err.message);
        });
  }

  formatLocalTime(date){
    // console.log(date);
    // return new Date(date+'Z');
    return new Date(date);
  }

  formatLocalTime2(date){
    // console.log(date);
    return new Date(date+'Z');
  }

  openDocumentCreateModal() {

     this.userDocument = new UserDocumentsAddModel();
     this.userDocument.userId = this.clientId;
    // this.userDocument.client = this.client;

    // this.modalRef = this.modalService.show(AddAppointmentComponent,  {
    //   initialState: {
    //     title: 'Add Appointment',
    //     data: {
    //       appointment:this.pendingAppointmentOne
    //     },
    //   },
    //   class: "modal-dialog modal-lg modal_lg_width appointment_modal"
    // });



    this.modalRef = this.modalService.show(AddUserDocumentsComponent,  {
      initialState: {
        title: 'Add Document',
        data: {
          userDocument:this.userDocument,
          xeppoCode: this.xeppoCode,
          xeppoGroupCode: this.xeppoGroupCode,
          companyCode: this.companyCode
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });

    this.modalRef.content.event.subscribe(result => {

      if (result == 'OK') {
        //console.log("Success");
        //this.userDocuments(this.clientId);
        this.router.navigate(['client/view'], { queryParams: {id: this.clientId }});

      }

    });
  }

  

  openInvoiceCreateModal(){
    let invoice = new InvoiceAddModel();
    if(this.clientId!=null){
      invoice.userId = this.clientId;
    }

    this.modalRef = this.modalService.show(AddInvoiceComponent,  {
      initialState: {
        title: 'Add Invoice',
        data: {
          invoice:invoice
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });

    this.modalRef.content.event.subscribe(result => {

      if (result == 'OK') {
        this.refresh();
      }

    });
  }

  refresh() {
    this.showInvoice=false;
    setTimeout(x=>this.showInvoice=true);
  }

  openCallModal(appointment:any) {
    let initialState = {
      title: 'Call with user',
      data: {
        appointment:appointment
      },
    };

    this.modalRef = this.modalService.show(CallComponent,  {
      initialState,
      class: "video_modal",
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'call_disconnected') {
        //console.log("8888888");
        this.modalRef.hide();
        //this.appointmentService.disconnectCall(appointment); client/view
        this.router.navigate(['/client/view?id?'+this.clientId]);
        //console.log("Success");
         // this.getAppointmentList();
      }
    });
  }


  openEditModal(appointment:any) {
    //console.log(1111111111);
    //console.log(appointment);


    let scheduledDat = appointment.sheduledDate;
      //appointment.sheduledDate = this.formatLocalTime(scheduledDat);//.format('YYYY-MM-DD'); // hh:mm a
      //appointment.hour = moment(scheduledDat).format('hh');
      //appointment.minute = moment(scheduledDat).format('mm');
      //appointment.amPm = moment(scheduledDat).format('A');

      //console.log(appointment.sheduledDate);

    this.modalRef = this.modalService.show(EditAppointmentComponent,  {
      initialState: {
        title: 'Edit Appointment',
        data: {
          appointment:appointment
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog modal-lg modal_lg_width"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getAppointmentListCurrentUser(this.clientId);
        // this.router.navigate(['/client/view?id?'+this.clientId+'&clientId='+this.clientClientId]);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['client/view'], { queryParams: { id: this.clientId, clientId: this.clientClientId }});
      }
    });

  }

  showTabSetValue(value){
    this.showTab = value;
  }

  showFactLabel(event){
    this.factTitle = event.target['options'][event.target['options'].selectedIndex].text;
    this.factFinderCategoryId = event.target.value;
    this.getFactFinderListFiltered(this.factFinderCategoryId);
  }

  GetWebClient(xeppoCode){
    this.clientsService.getWebClient(xeppoCode, this.companyCode).subscribe((webClientData:WebClientResponse)=>{

      this.webClient = webClientData.response;
      // console.log("web client details ==========");
      // console.log(this.webClient);
      if(this.webClient){
        this.xeppoGroupCode = this.webClient.xeppoGroupCode;
      }

      if(this.webClient && this.webClient.client){
        if (this.client.client.firstName != null ) {
          this.clientName = this.webClient.client.firstName+" ";
        }
        if (this.client.client.lastName != null ) {
          this.clientName = this.clientName+this.webClient.client.lastName;
        }
      }

      if(this.webClient && this.webClient.taxReturns){
        this.webClient.taxReturns.forEach(element => {
          this.taxableIncomeVal.push (element.taxableIncome);
          this.taxableIncomeYear.push (element.financialYear);
        });
      }

      if(this.webClient && this.webClient.insurance) {
        this.webClient.insurance.forEach(element => {
          this.total_annual_premiums= this.total_annual_premiums + element.premium;

          if(element.insurancePolicyCoverViewModel) {
            let sum_insured=0;

            element.insurancePolicyCoverViewModel.forEach(element2 => {
              sum_insured= sum_insured + element2.sumInsured;
            });
            this.sumInsured.push (sum_insured);
          }
        });
      }


      // this.webClient.brokageByFeeCategory.forEach(element => {
      //     this.groupByBrokeragebyYear [element.financialYear] = this.groupByBrokeragebyYear [element.financialYear] || [];
      //     this.groupByBrokeragebyYear [element.financialYear].push( { feeCategoryName: element.feeCategoryName ,financialYear: element.financialYear, gross: element.gross, net: element.net, sortYear: element.sortYear });
      // });

      if(this.webClient && this.webClient.brokageByFeeCategory){
        this.groupArr = new Set(this.webClient.brokageByFeeCategory.map(item => item.financialYear)),
          this.result = [];
          this.groupArr.forEach(g =>
          this.result.push({
            groupFinancialYear: g,
            values: this.webClient.brokageByFeeCategory.filter(i => i.financialYear === g)
          }
        ));
      }

      if(this.webClient && this.webClient.assets){
        // this.taxableIncomeVal.push (element.taxableIncome);
        let webAssets = this.transform(this.webClient.assets,'netWorthAccount');


        for(var i=0;i< webAssets.length;i++){
          let total =0;
          webAssets[i].value.forEach(element => {
            total=total+ element.current;
          });

          let asst = this.transform(webAssets[i].value,'netWorthAllocation');
          for(var j=0;j< asst.length;j++){
            let total_sub =0;
            asst[j].value.forEach(element => {
              total_sub=total_sub+ element.current;
            });
            asst[j].total = total_sub;
          }

          webAssets[i].value = asst;
          webAssets[i].total = total;
        }

        this.assetList = webAssets;
      }

      if(this.webClient && this.webClient.assets){
        // this.taxableIncomeVal.push (element.taxableIncome);
        let webAssets = this.transform(this.webClient.assets,'netWorthAccount');


        for(var i=0;i< webAssets.length;i++){
          //console.log(webAssets[i].value);
          let total =0;
          webAssets[i].value.forEach(element => {
            total=total+ element.current;
          });

          let asst = this.transform(webAssets[i].value,'netWorthAllocation');
          for(var j=0;j< asst.length;j++){
            let total_sub =0;
            asst[j].value.forEach(element => {
              total_sub=total_sub+ element.current;
            });
            asst[j].total = total_sub;
          }

          webAssets[i].value = asst;
          webAssets[i].total = total;
        }

        this.assetList = webAssets;
      }

      if(this.webClient && this.webClient.liabilities){
        // this.taxableIncomeVal.push (element.taxableIncome);
        let webLiabilities = this.transform(this.webClient.liabilities,'netWorthAccount');


        for(var i=0;i< webLiabilities.length;i++){
          //console.log(webAssets[i].value);
          let total =0;
          webLiabilities[i].value.forEach(element => {
            total=total+ element.current;
          });

          let asst = this.transform(webLiabilities[i].value,'netWorthAllocation');
          for(var j=0;j< asst.length;j++){
            let total_sub =0;
            asst[j].value.forEach(element => {
              total_sub=total_sub+ element.current;
            });
            asst[j].total = total_sub;
          }

          webLiabilities[i].value = asst;
          webLiabilities[i].total = total;
        }

        this.liabilityList = webLiabilities;
      }

      // console.log("group By Brokerage by Year ==========");
      // console.log(this.groupByBrokeragebyYear);
      //console.log("group By Brokerage by Year ++++++++++");
      //console.log(this.result);

      this.isLoaded1 = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);

      });
  }


  public feesChartOptions = {
    elements: {
      rectangle: {
          borderWidth: 2,
          borderColor: 'rgb(0, 255, 0)',
          borderSkipped: 'bottom'
      }
  },
  responsive: true,
  maintainAspectRatio: false,
  responsiveAnimationDuration:500,
  legend: {
      position: 'top',
      display: false
  },
  scales: {
      xAxes: [{
          display: true,
          gridLines: {
              color: "#f3f3f3",
              drawTicks: false,
          },
          scaleLabel: {
              display: true,
          }
      }],
      yAxes: [{
          display: true,
          gridLines: {
              color: "#f3f3f3",
              drawTicks: false,
          },
          scaleLabel: {
              display: true,
          }
      }]
  },
    title: {
      display: false,
      text: 'Chart.js Bar Chart'
  }
  };


  public feesChartLabels = this.taxableIncomeYear;
  public feesChartType = 'bar';
  public feesChartLegend = true;
  public feesChartColors = [
    {
      borderColor: "#15a9c8",
      backgroundColor: '#15a9c8',
    },
  ];
  public feesChartData = [
    {
      data: this.taxableIncomeVal,
      borderWidth: 4,
      borderColor: '#15a9c8',
      pointBackgroundColor: "#FFF",
      pointBorderColor: "#15a9c8",
      pointHoverBackgroundColor: "#FFF",
      pointHoverBorderColor: "#15a9c8",
      pointRadius: 0,
      pointHoverRadius: 6,
      fill: true,
    }
  ];

  transform(collection: any, property: string) {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if(!collection) {
        return null;
    }

    const groupedCollection = collection.reduce((previous, current)=> {
        if(!previous[current[property]]) {
            previous[current[property]] = [current];
        } else {
            previous[current[property]].push(current);
        }

        return previous;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key],total:0 }));
}

  getFactFinderCategories(){
    this.factFinderService.getFactFinderCategories().subscribe((data:FactFinderCategoriesResponse)=>{
      if(data.success){
        this.showFactFinderCat = true;
        this.factFinderCategoriesView = data.response;
        // console.log(this.factFinderCategoriesView);
        if(this.factFinderCategoriesView.length > 0){
          this.factTitle = this.factFinderCategoriesView[0].name;
          this.factFinderCategoryId = this.factFinderCategoriesView[0].id;
        }
        // console.log("   == this.factTitle ==  ");
        // console.log(this.factTitle);
        // console.log(this.factFinderCategoryId);
        //this.getFactFinderList()
      }
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getFactFinderList(){
    this.factFinderService.getFactFinderList(this.clientId).subscribe((data:FactFinderResponse)=>{
      if(data.success){
        this.showFactFinderCat = true;
        this.factFinders = data.response;
        // console.log(this.factFinders);
        this.getFactFinderListFiltered(this.factFinderCategoryId);
        //this.factFindersFiltered = Object.assign({}, invoiceModel);
      }
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getFactFinderListFiltered(factFinderCategoryId : string){
    // let factFinderCategoryStr :string = this.factFinderCategoryId;

    //this.factFindersFiltered = this.factFinders.find(x => x.categoryId == factFinderCategoryId);

    this.factFindersFiltered = this.factFinders.filter(function(ff){
      return ff.categoryId == factFinderCategoryId;
    });
    this.getFactGoalList(this.factTitle);
    //console.log(this.factFindersFiltered);
  }

  public openConfirmationDialog(template: TemplateRef<any>,id:string) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });

    this.document_id = id;
    this.message = 'Are you sure that you want to delete?'

    //console.log(this.message);
    //console.log(id);
  }

  documentDelete(document_id): void {
    this.modalRef.hide();
    //user.fortressAdmin.isArchived = status;
    //console.log(user.fortressAdmin.isArchived);

    this.userDocumentService.deleteUserDocument(document_id).subscribe(()=>{
      this.userDocuments(this.clientId);
      this.toastr.success('Document deleted successfully.');
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
      this.toastr.error('Error on deleting document.');
    });
  }

  decline(document_id): void {
    this.modalRef.hide();

  }

  getFactGoalList(factTitle){
    // console.log('-----factTitle----');
    // console.log(factTitle);
    if(factTitle == 'Financial/Personal Goals') {
      this.factFinderService.getFactGoalShort(this.clientId).subscribe((data:FactFinderResponse)=>{
        if(data.success){
          this.facGoalShort = data.response;
          // console.log('-----facGoalShort----');
          // console.log(this.facGoalShort);
        }
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });

      this.factFinderService.getFactGoalMedium(this.clientId).subscribe((data:FactFinderResponse)=>{
        if(data.success){
          this.facGoalMedium = data.response;
          // console.log('-----facGoalMedium----');
          // console.log(this.facGoalMedium);
        }
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });

      this.factFinderService.getFactGoalLong(this.clientId).subscribe((data:FactFinderResponse)=>{
        if(data.success){
          this.facGoalLong = data.response;
          // console.log('-----getFactGoalLong----');
          // console.log(this.facGoalLong);
        }
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
    }

    if(factTitle == 'Personal Details') {
      this.factFinderService.getFactDependent(this.clientId).subscribe((data:FactFinderResponse)=>{
        if(data.success){
          this.facDependent = data.response;
          // console.log('-----facDependent----');
          // console.log(this.facDependent);
        }
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
    }

    if(factTitle == 'Expenses') {
      this.factFinderService.getFactExpenses(this.clientId).subscribe((data:FactFinderResponse)=>{
        if(data.success){
          this.factExpenses = data.response;

          this.groupArr2 = new Set(this.factExpenses.map(item => item.facExpenseCategory)),
            this.result2 = [];
            this.groupArr2.forEach(g =>
            this.result2.push({
              groupFacExpenseCategory: g,
              values: this.factExpenses.filter(i => i.facExpenseCategory === g)
            }
          ));

          // console.log('-----getFactExpenses----');
          // console.log(this.result2);
        }
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
    }

    // if(factTitle == 'Expenses') {
    //   this.factFinderService.getFacEntity(this.clientId).subscribe((data:FactFinderResponse)=>{
    //     if(data.success){
    //       this.facEntity = data.response;
    //       // console.log('-----facEntity----');
    //       // console.log(this.facEntity);
    //     }
    //   },
    //   (err: HttpErrorResponse)=>{
    //     //console.log("Error"+ err.message);
    //   });
    // }

    if(factTitle == 'Insurance') {
      this.factFinderService.getFacInsurance(this.clientId).subscribe((data:FactFinderResponse)=>{
        if(data.success){
          this.facInsurance = data.response;
          // console.log('-----facInsurance----');
          // console.log(this.facInsurance);
        }
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
    }

  }


  getFactFinderNewList(){
    this.factFinderService.getFacFinder(this.clientId).subscribe((data:FactFinderNewResponse)=>{
      if(data.success){
        this.showFactFinderCat = true;
        this.factFindersNew = data.response;
        // console.log(this.factFindersNew);
      }
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  ClosePopup(){
    this.modalRef.hide();
  }

  openViewModal(factFinderNew:any) {

    this.modalRef = this.modalService.show(ViewFactComponent,  {
      initialState: {
        title: 'View Fact',
        data: {
          factFinderNew:factFinderNew
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-xl"
    });

    // this.modalRef.content.event.subscribe(result => {

    //   if (result == 'OK') {
    //     //console.log("Success");
    //     this.router.navigate(['agents']);
    //      // this.getAgentList();

    //   }

    // });
  }


  public openConfirmationDialog1(template: TemplateRef<any>, isStaffMember) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });


    //this.message = 'Are you sure that you want to delete?'

    if(!isStaffMember){
      this.message = 'Are you sure that you want to mark as portal client?'
    }
    else{
      this.message = 'Are you sure that you want to remove mark as portal client?'
    }

    //console.log(this.message);
    //console.log(id);
  }

  
  public openConfirmationDialog4(template: TemplateRef<any>, isVisibleDocument) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });


    //this.message = 'Are you sure that you want to delete?'

    if(isVisibleDocument){
      this.message = 'Are you sure that you want to make the documents visible to the client group ?'
    }
    else{
      this.message = 'Are you sure that you want to remove make the documents visible to the client group ?'
    }

    //console.log(this.message);
    //console.log(id);
  }

  openRealtimeNetworth() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/net-worth`],{ queryParams: { xeppoCode: this.client.client.xeppoCode, firstName: this.webClient.client.firstName, lastName: this.webClient.client.lastName}})
    );
    window.open(url, '_blank');
  }

  openMigrateConfirmDialog(template: TemplateRef<any>, isMigrate: boolean) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    if(isMigrate) {
      this.message = 'Are you sure that you want to revert this migration? you can enable it back any time.'
    } else {
      this.message = 'Are you sure that you want to migrate this client? Once it done you can revert it back any time to the previous.'
    }
  }

  migrate(active: boolean, isMigrate: boolean) {
    if(active) {
      this.loaded = true;
      this.ngxService.start();
      if(isMigrate) {
          this.clientsService.revertNetworthData(this.xeppoCode, this.companyCode).subscribe((data:clientRevertResponse)=>{
            if(data.success) {
              this.isMigrated = !data.response;
              this.setMoneysoftLink(!data.response);
              this.GetWebClient(this.xeppoCode);
              this.modalRef.hide();
              if (data.response) {
                this.toastr.success('Revert migration success');
              } else {
                this.toastr.error('Revert migration failed');
              }
            } else {
              this.modalRef.hide();
              this.toastr.error('Revert migration error');
            }
            this.ngxService.stop();
            this.loaded = false;
          },err=> {
            this.toastr.error('Revert migration failed');
            this.modalRef.hide();
            this.ngxService.stop();
            this.loaded = false;
          });
      } else {
        this.clientsService.MigrateNetworthData(this.xeppoCode, this.companyCode).subscribe((data:clientMigrateResponse)=>{
          if(data.success) {
            this.isMigrated = data.response;
            this.setMoneysoftLink(data.response);
            this.GetWebClient(this.xeppoCode);
            this.modalRef.hide();
            if(data.response) {
              this.toastr.success('Migrated successfully');
            } else {
              this.toastr.error('Migration failed');
            }
          } else {
            this.modalRef.hide();
          }
          this.ngxService.stop();
          this.loaded = false;
        },err=> {
          this.modalRef.hide();
          this.toastr.error('Migration failed');
          this.ngxService.stop();
          this.loaded = false;
        });
      }
    }
  }

  closeMigrate(active: boolean) {
    this.modalRef.hide();
  }

  confirmMarkAsPortalClient(isStaffMember): void {
    this.modalRef.hide();
    this.isStaffMember = !isStaffMember;
    this.updateUserDetails();

  }


  public openConfirmationDialog2(template: TemplateRef<any>, isVisibleToStaff) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });

    // this.isVisibleToStaff = !isVisibleToStaff;
    //this.message = 'Are you sure that you want to delete?'

    if(isVisibleToStaff){
      this.message = 'Are you sure that you want to mark as view only admin?'
    }
    else{
      this.message = 'Are you sure that you want to remove mark as view only admin?'
    }

    //console.log(this.message);
    //console.log(id);
  }


  confirmViewOnlyForAdmin(isVisibleToStaff): void {
    this.modalRef.hide();
    this.isVisibleToStaff = !isVisibleToStaff;
    this.updateUserDetails();
  }

  confirmViewVisibleDocument(isVisibleDocument): void {
    this.modalRef.hide();
    this.isVisibleDocument = !isVisibleDocument;
    this.updateUserDetails();
  }

  updateUserDetails(){
    let visibility = {
      "isStaffMember": this.isStaffMember,
      "isVisibleToStaff": this.isVisibleToStaff,
      "isVisibleDocument": this.isVisibleDocument,
    }

    // console.log("--visibility--");
    // console.log(visibility);
    this.clientsService.clientUpdate(visibility, this.clientClientId).subscribe((data:UserVisibilityResponse)=>{
      if(data && data.success){
        //this.router.navigate(['/client/view?id?'+this.clientId]);
        this.router.navigate(['client/view'], { queryParams: { id: this.clientId, clientId: this.clientClientId }});
      }

      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  viewPortalPermission(permission){
    return this.authService.viewPortalPermission(permission);
  }

  public openConfirmationDialog3(template: TemplateRef<any>, isActive) {
    // console.log(this.clientId);
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });

    // this.isActive = !isActive;
    //this.message = 'Are you sure that you want to delete?'

    if(isActive){
      this.message = 'Are you sure that you want to disable client?'
    }
    else{
      this.message = 'Are you sure that you want to enable client?'
    }

    //console.log(this.message);
    //console.log(id);
  }


  confirmClientStatus(isActive): void {
    this.modalRef.hide();
    this.isActive = !isActive;
    this.updateClientStatus();
  }

  updateClientStatus(){

    let visibility = {
      "isStaffMember": this.isStaffMember,
      "isActive": this.isActive
    }

    // console.log("--visibility--");
    // console.log(visibility);
    this.clientsService.clientStatusUpdate(this.isActive, this.clientId).subscribe((data:UserVisibilityResponse)=>{
      if(data && data.success){
        //this.router.navigate(['/client/view?id?'+this.clientId]);
        this.router.navigate(['client/view'], { queryParams: { id: this.clientId }});
      }

      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  openPrimaryMobileEditModal(client) {
    // this.userDocument = new UserDocumentsAddModel();
    // this.userDocument.userId = this.clientId;
    this.modalRef = this.modalService.show(AddPrimaryMobileComponent,  {
     initialState: {
       heading_title: 'Edit Contact',
       data: {
        //  userDocument:this.userDocument,
         client : client,
         title: 'Add Document',
       },
     },
     keyboard: false,
     backdrop: true,
     ignoreBackdropClick: true,
     class: "modal-lg"
    });

    this.modalRef.content.event.subscribe(result => {

      if (result == 'OK') {
        this.ngOnInit();
        // console.log("Success");
        //  this.router.navigate(['client/view'], { queryParams: {id: this.clientId }});
      }
    });
 }

 openUserNameEditModal(client) {
  // this.userDocument = new UserDocumentsAddModel();
  // this.userDocument.userId = this.clientId;
  this.modalRef = this.modalService.show(AddUserNameComponent,  {
   initialState: {
     heading_title: 'Edit User Name',
     data: {
      //  userDocument:this.userDocument,
       client : client,
       title: 'Edit User Name',
     },
   },
   keyboard: false,
   backdrop: true,
   ignoreBackdropClick: true,
   class: "modal-lg"
  });

  this.modalRef.content.event.subscribe(result => {

    if (result == 'OK') {
      this.ngOnInit();
      // console.log("Success");
      //  this.router.navigate(['client/view'], { queryParams: {id: this.clientId }});
    }
  });
}

netWorthHistory(){
  let xeppoGroupCode = 0;
  if(this.webClient){
    xeppoGroupCode = this.webClient.xeppoGroupCode;
  }
  this.router.navigate(['client/view/networth-history'], { queryParams: { xeppoCode: this.client.client.xeppoCode, clientCode: this.clientCode, xeppoGroupCode: xeppoGroupCode, clientId: this.clientId, clientClientId: this.clientClientId }});
  // this.router.navigate(['/net-worth'], { queryParams: { xeppoCode: this.client.client.xeppoCode, firstName: this.webClient.client.firstName, lastName: this.webClient.client.lastName}})
}

fds(){
  this.SASectionRef.nativeElement.scrollIntoView();
  // this.router.navigate(['agreement'], { queryParams: { id : this.clientId , clientCode : this.clientCode, xeppoCode : this.xeppoCode, xeppoGroupCode: this.xeppoGroupCode}});
}

realTimeNetworth() {
 // this.router.navigate(['/net-worth'], { queryParams: { xeppoCode: this.client.client.xeppoCode, firstName: this.webClient.client.firstName, lastName: this.webClient.client.lastName}})
  const url = this.router.serializeUrl(
    this.router.createUrlTree([`/net-worth`],{ queryParams: { xeppoCode: this.client.client.xeppoCode, firstName: this.webClient.client.firstName, lastName: this.webClient.client.lastName}})
  );
  window.open(url, '_blank');
}

getNWReportSummaryDocument(){
  let clientId = this.clientId
  var date = new Date();
  var currentDay = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ssZ"); // "2021-03-22T11:42:48.896Z";

  // // this.ngxService.start();
  // this.clientsService.getNWReportSummaryDocument(clientId, currentDay).subscribe((data)=>{
  //   saveAs(data, 'NetWorthReportDetailDocument.pdf');
  // },err=>{
  //   console.log(err);
  //   this.toastr.error('Error on downloading document.');
  // });
  // // this.ngxService.stop();

  this.isLoading = true;
  this.http.get('/assets/img/image@3x.png', { responseType: 'blob' })
  .subscribe(res => {
    const reader = new FileReader();
    var base64data;
    reader.onloadend = () => {
        base64data = reader.result;
          const file = this.dataURLtoFile(base64data, 'a.png');
          console.log('hhhhhhh ==',file);

         // if(this.isVerified) {
              this.clientsService.getNWReportSummaryDocument(clientId, currentDay, file).subscribe((data)=>{
              this.netWorthSummaryDocumentURL = data.response.s3Key;
              window.open( this.netWorthSummaryDocumentURL, '_blank');
              this.isLoading = false;
              // saveAs(data, 'NetWorthReportDetailDocument.pdf');
            },err=>{
              console.log(err);
              this.toastr.error('Error on downloading document.');
              this.isLoading = false;
            });
        //  } else {
          //  this.isLoading = false;
          //  this.toastr.error('Cannot download the document. client Group is Not Verifed');
         // }

    }

    reader.readAsDataURL(res);
  });

  // this.clientsService.getNWReportSummaryDocument(clientId, currentDay).subscribe((data:any)=>{
  //     if(data && data.success){
  //       this.toastr.success('File downloaded successfully.');
  //     }
  //   },
  //   (err: HttpErrorResponse)=>{
  //     //console.log("Error"+ err.message);
  //     this.toastr.error('Error on downloading document.');
  //   });
}

getNWReporttDetailWithChartsDocument(){
  let clientId = this.clientId
  var date = new Date();
  var currentDay = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ssZ"); // "2021-03-22T11:42:48.896Z";

  this.isLoading = true;
  this.http.get('/assets/img/image@3x.png', { responseType: 'blob' })
  .subscribe(res => {
    const reader = new FileReader();
    var base64data;
    reader.onloadend = () => {
        base64data = reader.result;
          const file = this.dataURLtoFile(base64data, 'a.png');
          console.log('hhhhhhh ==',file);

         // if(this.isVerified) {
              this.clientsService.getNWReporttDetailWithChartsDocument(clientId, currentDay, file).subscribe((data)=>{
              this.netWorthReporttDetailWithChartsURL = data.response.s3Key;
              window.open( this.netWorthReporttDetailWithChartsURL, '_blank');
              this.isLoading = false;
              // saveAs(data, 'NetWorthReportDetailDocument.pdf');
            },err=>{
              console.log(err);
              this.toastr.error('Error on downloading document.');
              this.isLoading = false;
            });
         // } else {
         //   this.isLoading = false;
         //   this.toastr.error('Cannot download the document. client Group is Not Verifed');
         // }

    }

    reader.readAsDataURL(res);
  });
}

getNetWorthReportDetailDocument(){
  let clientId = this.clientId
  var date = new Date();
  var currentDay = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ssZ"); // "2021-03-22T11:42:48.896Z";

  this.isLoading = true;
 // if(this.isVerified) {
    this.clientsService.getNWReportDetailDocument(clientId, currentDay).subscribe((data)=>{
      this.netWorthReportDetailURL = data.response.s3Key;
      window.open( this.netWorthReportDetailURL, '_blank');
      this.isLoading = false;
      // window.open(data.response.s3Key, '_blank')
      // saveAs(data.response.s3Key, 'NetWorthReportDetailDocument.pdf');
  
      // this.toastr.success('File downloaded successfully.');
    },err=>{
      console.log(err);
      this.toastr.error('Error on downloading document.');
      this.isLoading = false;
    });
  // } else {
  //   this.isLoading = false;
  //   this.toastr.error('Cannot download the document. client Group is Not Verifed');
  // }
}

getInsuranceReportDocument(){
  let clientId = this.clientId
  var date = new Date();
  // var currentDay = new Date(date.getFullYear(), date.getMonth() - 0 + 1, 0);
  var currentDay = this.datepipe.transform(date, "yyyy-MM-dd'T'HH:mm:ssZ"); // "2021-03-22T11:42:48.896Z";

  // this.ngxService.start();
  this.isLoading = true;
  //if(this.isVerified) {
      this.clientsService.getInsuranceReportDocument(clientId, currentDay).subscribe((data)=>{
      // saveAs(data, 'NetWorthReportDetailDocument.pdf');
      this.insuranceReportURL = data.response.s3Key;
      window.open( this.insuranceReportURL, '_blank');


      this.isLoading = false;
      // window.open(data.response.s3Key, '_blank')
    },err=>{
      console.log(err);
      this.toastr.error('Error on downloading document.');
      this.isLoading = false;
    });
 // } else {
 //   this.isLoading = false;
 //   this.toastr.error('Cannot download the document. client Group is Not Verifed');
 // }
  // this.ngxService.stop();
}

dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  handleReaderLoaded(e) {
    this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
  }

  // Download Document
  downloadDocument(documentUrl: string, name: string) {

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getNewsFeedList(){
    console.log('newsFeedList list ---------');
    this.newsFeedService.newsFeedGetByUserList(this.clientId).subscribe((data:NewsFeedResponseProfile)=>{
      this.newsFeedList = data.response;
      console.log('newsFeedList list ---------');
      console.log(this.newsFeedList);
      this.isLoaded3 = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  formatLocalTime_Z(date){
    return new Date(date+'Z');
  }

  checkIsExpired(expiredDate) {
    if(expiredDate) {
      var date_1 = new Date(expiredDate);
      var userEntered = new Date(Date.UTC(date_1.getFullYear(), date_1.getMonth(), date_1.getDate() ));

      var now = new Date();
      var today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() ));

      if(userEntered.getTime() >= today.getTime()) {
          return true
      } else {
        return false
      }
    }

  }

  viewNewsFeedPopup(newsFeed:any){
    //console.log(newsFeed);
    this.modalRef = this.modalService.show(ViewNewsFeedComponent,  {
      initialState: {
        title: 'View News Feed',
        data: {
          newsFeed:newsFeed,
          viewIn:'profile'
        },
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-lg_newsfeed",


    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
          //this.getNewsFeedList();

      }
    });
  }

  getLoginHistory(){
    this.clientsService.getLoginHistory(this.clientId).subscribe((loginHistoryData:any)=>{

      this.loginHistoryData = loginHistoryData.data;
      this.loginHistoryData.sort(this.sortFunction);​
      this.loginHistoryDataLength = this.loginHistoryData.length;
      const size = 3
      this.loginHistoryData = this.loginHistoryData.slice(0, size)
      // console.log('gggggg == ', this.loginHistoryData)
      this.isLoaded4 = true;

    });
  }

  openUserHistoryModal(){
    this.modalRef = this.modalService.show(UserHistoryComponent,  {
      initialState: {
        title: 'Add Appointment',
        data: {
          clientId:this.clientId
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog modal-md "
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
      }

    });
  }

  sortFunction(a,b){  
    var dateA = new Date(a.lastActiveDate).getTime();
    var dateB = new Date(b.lastActiveDate).getTime();
    return dateA < dateB ? 1 : -1;  
  }; 

  getFTree() {
    if(localStorage.getItem('imageURL')) {
      this.ngxService.start();
      this.modalRef = this.modalService.show(FamilyTreePdfComponent,  {
        initialState: {
          title: 'Family Tree',
          data: {
            firstName: this.webClient.client.firstName,
            lastName: this.webClient.client.lastName
          },
        },
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: "modal-xl"
      });
  
      this.modalRef.content.downloded.subscribe((res:any) => {
        if(res){
          this.modalRef.hide();
          this.ngxService.stop()
        }
      });
    } else {
      this.toastr.error('Cannot download family tree');
    }
    
  }

  drop(event: CdkDragDrop<any[]>) {
    console.log('Drag event triggered', event);
    moveItemInArray(this.userDocumentList, event.previousIndex, event.currentIndex);
  }
}
