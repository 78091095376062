import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { fdsRecipients, fdsServiceAgreementAddModel, fdsServiceDocuments, ServiceAgreementAddModel, fdsServiceDocumentsAdd, fdsOldRecipients, fdsServiceOldDocuments, ServiceAgreementListModel } from 'src/app/shared/models/service-agreement.model';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';
import { ToastrService } from 'ngx-toastr';
import { Utility } from 'src/app/utility/utility';
import { FileUploadResponse, ServiceAgreementProfileResponse, ServiceAgreementResponseProfile, UserGroupResponse } from 'src/app/shared/models/response.model';
import { FileUploadService } from '../../file-upload/file-upload.service';
import { ServiceAgreementService } from 'src/app/service-agreement/service-agreement.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserGroupAddModel } from 'src/app/shared/models/user-group.model';
import moment from 'moment';
import { DocumentUploadType } from 'src/app/shared/models/user-documents.model';

@Component({
  selector: 'app-new-service-agreement',
  templateUrl: './new-service-agreement.component.html',
  styleUrls: ['./new-service-agreement.component.css']
})
export class NewServiceAgreementComponent implements OnInit {

  Isvalid: boolean;
  IsvalidDocument: boolean = true;
  IsvalidRecipient: boolean = true;
  acceptDocType: string;
  serviceAgreementModel: ServiceAgreementAddModel;
  errors;
  fileUploadAddModel: FileUploadAddModel;
  docUploadSuccess: boolean = false;
  documentLabel = "Choose file";
  type: DocumentType;
  minDate = undefined;
  event: EventEmitter<any> = new EventEmitter();
  companyCode: any;
  templateList: any;
  fdsServiceAgreementAddModel: fdsServiceAgreementAddModel;
  fdsServiceDocuments: fdsServiceDocuments;
  fdsServiceDocumentsAdd: fdsServiceDocumentsAdd;
  fdsRecipients: fdsRecipients;
  fileSize: any = "";
  fileData: any = "";
  documentsArray: any;
  clickedAdd: boolean = false;
  data
  xeppoGroupCode: any;
  xeppoCode: any;
  clientCode: any;
  userGroupList;
  isLoaded2: boolean;
  isLoaded3: boolean;
  searchStr;
  searchResults: UserGroupAddModel[] = [];
  searchText: string = '';
  isShowRecipient: boolean = false;
  fdsRecipientsList = [];
  accessToken: string = '';
  fileRequired: boolean = true;
  templateIdRequired: boolean = true;
  expireDateRequired: boolean = true;
  valueCount: any;

  public iframeUrl: any;
  public loading: boolean;
  public loadingDU: boolean;
  public invalid: any;
  public invalidEmail: boolean;
  public invalidRecepientEmail: boolean;

  public fdsRecipientsOldList: fdsOldRecipients[];
  public edit: boolean;
  public fdsServiceOldDocuments: fdsServiceOldDocuments[];
  public fdsServiceAgreementUpdateRequestData: any = {};
  public fdsId: string;
  public surName: string;
  public recipientsListAll: any;
  public fdsStatus: boolean = true;
  serviceAgreementList: ServiceAgreementListModel[];
  public signList: any = [];
  public isdocumentDelete: boolean = false;
  IsUpdateBtnClick: boolean = false;
  IsDraft: boolean = true;
  ISCorrecting: boolean = false;
  serviceId: any;
  public DocUsersList: any;
  selectedDocusignUser: string;
  DocusignUserFullName: string;
  constructor(
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private fileUploadService: FileUploadService,
    public modalRef: BsModalRef,
    private serviceAgreementService: ServiceAgreementService,
    private cookieService: CookieService,
    private modalService: BsModalService,

  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  ngOnInit(): void {
    this.IsDraft = this.data.isDraft;
    this.ISCorrecting = this.data.IsCorrect;
    this.serviceId = this.data.Id;
    this.valueCount = 0;
    this.isdocumentDelete = false;
    this.signList = this.data.signList;
    this.fdsStatus = this.data?.fdsServiceAgreementAddModel?.fdsAgreementStatus == 1 ? false : true;
    console.log(this.fdsStatus)
    this.iframeUrl = this.data.url

    if (this.iframeUrl)
      this.IsDraft = false
    if (!this.iframeUrl) {
      this.getDSToken();
    }
    if (this.data.xeppoGroupCode != null) {
      this.xeppoGroupCode = this.data.xeppoGroupCode;
      this.xeppoCode = this.data.xeppoCode;
      this.clientCode = this.data.clientCode;
      this.surName = this.data.surName;
    }

    this.Isvalid = true;
    this.acceptDocType = ".pdf,.doc,.docx";  // ".pdf,.doc,.docx"
    this.companyCode = this.cookieService.get('companyCode');
    this.templateList = [{ "id": "1", "value": "Template 01" }, { "id": "2", "value": "Template 02" }, { "id": "3", "value": "Template 03" }, { "id": "4", "value": "Template 04" }, { "id": "5", "value": "Template 05" }];

    this.serviceAgreementModel = new ServiceAgreementAddModel();
    this.fdsServiceAgreementAddModel = new fdsServiceAgreementAddModel();
    this.fdsServiceDocuments = new fdsServiceDocuments();
    this.fdsServiceDocumentsAdd = new fdsServiceDocumentsAdd();
    this.fdsRecipients = new fdsRecipients();
    this.fdsServiceDocumentsAdd.templateId = "000";

    // Upload file globale value Start ------
    this.serviceAgreementModel.fdsServiceDocuments = [];

    //default template
    const now = new Date();
    const today = moment(now).format('MM-YYYY');
    this.fdsServiceAgreementAddModel.title = this.surName + '- Documents To Action ' + today;
    this.fdsServiceAgreementAddModel.description = 'Please find enclosed your Documents To Action.\n\nReview and sign the provided form/s where indicated as soon as possible.'
    if (this.data && this.data.keyFirmContacts) {
      const contact = this.data.keyFirmContacts.filter(a => a.contactType === 'Client Adviser')[0]
      this.fdsServiceAgreementAddModel.advisorName = (contact || {}).name;
      this.fdsServiceAgreementAddModel.advisorEmail = (contact || {}).email;
    }
    this.fdsServiceAgreementAddModel.isAdvisor = 'false';

    this.documentsArray = [];
    this.fileUploadAddModel = new FileUploadAddModel();
    this.fileUploadAddModel.fileCategory = "5";
    this.fileUploadAddModel.fileType = "3"; // 3=PDF
    this.fileUploadAddModel.userId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));
    // Upload file globale value End ------

    this.fdsServiceAgreementAddModel.isDocVisibleToClient = true;

    this.getUserGroupList();
    if (this.data.fdsServiceAgreementAddModel) {
      this.edit = true;

      this.GetDocusignUserByEnvelopeId();
      this.fdsId = this.data.fdsServiceAgreementAddModel.id;
      this.fdsServiceAgreementAddModel.title = this.data.fdsServiceAgreementAddModel.title;
      this.fdsServiceAgreementAddModel.description = this.data.fdsServiceAgreementAddModel.description;
      this.fdsServiceAgreementAddModel.isAdvisor = this.data.fdsServiceAgreementAddModel.fdsRecipients[0].advisorEmail != '' ? 'true' : 'false';
      this.fdsServiceAgreementAddModel.advisorEmail = this.data.fdsServiceAgreementAddModel.fdsRecipients[0].advisorEmail;
      this.fdsServiceAgreementAddModel.advisorName = this.data.fdsServiceAgreementAddModel.fdsRecipients[0].advisorName;
      this.fdsServiceAgreementAddModel.isDocVisibleToClient = this.data.fdsServiceAgreementAddModel.isDocVisibleToClient;

      var date_1 = new Date(this.data.fdsServiceAgreementAddModel.expireDate);
      this.fdsServiceAgreementAddModel.expireDate = { "year": date_1.getFullYear(), "month": date_1.getMonth() + 1, "day": date_1.getDate() + 1 }

      this.fdsServiceOldDocuments = this.data.fdsServiceAgreementAddModel.fdsServiceDocuments?.filter(x => !x.isArchived);

      this.fdsRecipientsOldList = this.data.fdsServiceAgreementAddModel?.fdsRecipients?.filter(x => !x.isArchived);

      console.log('this.fdsRecipientsOldList', this.fdsRecipientsOldList)

      
      // this.fdsRecipientsOldList.forEach(element => {

      //   var getrecipientById = this.signList.filter(x => x.email == element.email && x.status == "completed" && !x.isArchived);
      //   if (getrecipientById[0]) {
      //     element.issignRec = getrecipientById[0].status;
      //     this.isdocumentDelete = true;
      //   }
      // });

    }

    if (!this.edit) {
      this.GetAllDocuSignUsers();
    }
  }

  onSubmit(form: NgForm) {
    this.fdsServiceAgreementAddModel.xeppoCode = +this.xeppoCode;
    this.fdsServiceAgreementAddModel.xeppoGroupCode = +this.xeppoGroupCode;
    this.fdsServiceAgreementAddModel.clientCode = this.clientCode;
    this.fdsServiceAgreementAddModel.fdsAgreementStatus = 1;

    let title = this.fdsServiceAgreementAddModel.title;
    let description = this.fdsServiceAgreementAddModel.description;
    let expireDate = this.fdsServiceAgreementAddModel.expireDate;
    let notArchivedOldDocs = [], notArchivedOldRecepients = [];

    if (this.edit) {
      //get not archived list for calc full array length
      notArchivedOldDocs = this.fdsServiceOldDocuments.filter(item => item.isArchived == false);
      notArchivedOldRecepients = this.fdsRecipientsOldList.filter(item => item.isArchived == false);
    }
    let documentsList = this.edit ? [...notArchivedOldDocs, ...this.serviceAgreementModel.fdsServiceDocuments] : this.serviceAgreementModel.fdsServiceDocuments;

    let recipientsList = this.edit ? [...notArchivedOldRecepients, ...this.fdsRecipientsList] : this.fdsRecipientsList;

    this.recipientsListAll = recipientsList;

    if (form.invalid || (title && title.trim() == "") || (description && description.trim() == "") || (expireDate && expireDate.length == 0) || (documentsList && documentsList.length == 0) || (recipientsList && recipientsList.length == 0) || this.invalid || this.invalidEmail || this.invalidRecepientEmail) {

      if (title && title.trim() == "") {
        form.controls['title'].setErrors({ 'required': true });
      }
      if (description && description.trim() == "") {
        form.controls['description'].setErrors({ 'required': true });
      }
      if (expireDate == null) {
        form.controls['expireDate'].setErrors({ 'required': true });
        this.expireDateRequired = false
      }

      if (documentsList.length == 0) {
        this.IsvalidDocument = false;
      }

      if (recipientsList.length == 0) {
        this.IsvalidRecipient = false;
      }

      if (form.pristine) {
      }
      this.Isvalid = false;
    }

    else {
      this.expireDateRequired = true
      this.Isvalid = true;
      let date = this.msqlDateFormat(form.value.expireDate);
      let fullDate = date + ' ' + '00:00.0000000';
      var date_1 = new Date(fullDate);
      this.fdsServiceAgreementAddModel.expireDate = date_1.toISOString();

      this.serviceAgreementModel.fdsServiceAgreementAddModel = this.fdsServiceAgreementAddModel;
      if (this.edit) {
        this.fdsServiceAgreementUpdateRequestData.fdsServiceAgreementUpdateModel = {
          expireDate: this.fdsServiceAgreementAddModel.expireDate,
          title: this.fdsServiceAgreementAddModel.title,
          description: this.fdsServiceAgreementAddModel.description,
          fdsAgreementStatus: this.fdsServiceAgreementAddModel.fdsAgreementStatus,
          isDocVisibleToClient: this.fdsServiceAgreementAddModel.isDocVisibleToClient,
        };

        this.fdsServiceAgreementUpdateRequestData.fdsServiceDocuments = this.fdsServiceOldDocuments;
        this.fdsServiceAgreementUpdateRequestData.fdsServiceNewDocuments = this.serviceAgreementModel.fdsServiceDocuments;

        this.fdsServiceAgreementUpdateRequestData.fdsOldRecipients = this.fdsRecipientsOldList;
        this.fdsServiceAgreementUpdateRequestData.fdsNewRecipients = this.fdsRecipientsList;
        // this.fdsServiceAgreementUpdateRequestData.fdsNewRecipients = this.fdsRecipientsList,
        // this.fdsServiceAgreementUpdateRequestData.fdsRecipients = this.fdsRecipientsOldList;
        this.editData(this.fdsServiceAgreementUpdateRequestData, this.fdsId);
      } else {
        this.addData();
      }

    }

  }

  modalRehide(flag: any) {
    var lockToken = sessionStorage.getItem('lockToken');
    var lockedByApp = sessionStorage.getItem('lockedByApp');
    var servicetId = sessionStorage.getItem('servicetId');
    if (flag && !this.IsDraft) {
      this.serviceAgreementService.setFDStatus(servicetId, 8).subscribe((data) => {
        if (data.success) {
        } else {
          this.toastr.error('Error on sending Fee Disclosure Statement to client.');
        }
      },
        (err: HttpErrorResponse) => {
          console.log("Error" + err.message);
        });


    }

    if (!flag && !this.IsDraft) {
      this.serviceAgreementService.setFDStatus(servicetId, 4).subscribe((data) => {
        if (data.success) {
        } else {
          this.toastr.error('Error on sending Fee Disclosure Statement to client.');
        }
      },
        (err: HttpErrorResponse) => {
          console.log("Error" + err.message);
        });


    }
    this.deleteLockModel(this.fdsId, lockedByApp, lockToken);

    //change status with logic
    // if (this.IsUpdateBtnClick && flag) {
    //   this.serviceAgreementService.setFDStatus(this.data?.fdsServiceAgreementAddModel.id, 8).subscribe((data) => {
    //     if (data.success) {
    //       // this.modalRehide(true);
    //     } else {
    //       this.toastr.error('Error on sending Fee Disclosure Statement to client.');
    //       // this.modalRehide(true);
    //     }
    //   },
    //     (err: HttpErrorResponse) => {
    //       console.log("Error" + err.message);
    //     });
    // } else {
    //   if (!this.IsDraft) {
    //     this.serviceAgreementService.setFDStatus(this.data?.fdsServiceAgreementAddModel.id, 4).subscribe((data) => {
    //       if (data.success) {
    //         console.log("step 5")
    //         this.deleteLock1(this.fdsId, lockedByApp, lockToken);

    //       } else {
    //         this.toastr.error('Error on sending Fee Disclosure Statement to client.');
    //         // this.modalRehide(true);
    //       }
    //     },
    //       (err: HttpErrorResponse) => {
    //         console.log("Error" + err.message);
    //       });
    //   }
    // }

    this.modalRef.hide();
    // if (flag == false) {
    //   this.deleteLockModel(this.fdsId, lockedByApp, lockToken);
    // }
    if (!this.modalRef) {
      return;
    }
    this.modalRef = null; //tempory chin
  }

  ClosePopup() {
    this.modalRef.hide();
  }

  msqlDateFormat(inDate) {
    var month;
    var day;
    month = ("0" + (inDate.month)).slice(-2);
    day = ("0" + (inDate.day)).slice(-2);
    return inDate.year + '-' + month + '-' + day;
  }

  deleteLockModel(fdsId: any, lockedByApp: any, lockToken: any) {
    this.loading = true;
    this.ngxService.start();
    this.serviceAgreementService.DeleteLock(fdsId, lockedByApp, lockToken).subscribe((res: any) => {
      if (res.success) {
      } else {
        this.modalRef.hide();
      }
      this.loading = false;
      this.ngxService.stop();
    }, err => {
      this.loading = false;
      this.ngxService.stop();
    })
  }

  deleteLock(fdsId: any, lockedByApp: any, lockToken: any, fdsServiceAgreementUpdateRequestData: any) {
    this.ngxService.start();
    this.loading = true;
    if (this.fdsServiceAgreementAddModel?.isDocVisibleToClient == false) {
      this.serviceAgreementService.updateServiceAgreement(fdsServiceAgreementUpdateRequestData, fdsId).subscribe((data: ServiceAgreementResponseProfile) => {
        if (data.error && !data.success) {
          this.toastr.error('Error on saving Fee Disclosure Statement.');
        } else {
          this.modalRef.hide();
          // this.loading = false;
          // this.ngxService.stop();
          this.toastr.success('Fee Disclosure Statement update successfully.');
          this.event.emit('OK');
        }
      }, err => {
        this.modalRef.hide();
        this.toastr.error('Error on Fee Disclosure Statement.');
      });
    } else {
      this.serviceAgreementService.DeleteLock(fdsId, lockedByApp, lockToken).subscribe((res: any) => {
        if (res.success) {
          if (this.fdsServiceAgreementAddModel?.isDocVisibleToClient == false) {
            // this.serviceAgreementService.updateServiceAgreement(fdsServiceAgreementUpdateRequestData, fdsId).subscribe((data: ServiceAgreementResponseProfile) => {
            //   if (data.error && !data.success) {
            //     this.toastr.error('Error on saving Fee Disclosure Statement.');
            //   } else {
            //     this.modalRef.hide();
            //     this.loading = false;
            //     this.ngxService.stop();
            //     this.toastr.success('Fee Disclosure Statement update successfully.');
            //     this.event.emit('OK');
            //   }
            // }, err => {
            //   this.modalRef.hide();
            //   this.toastr.error('Error on Fee Disclosure Statement.');
            // });
          } else {
            this.IsUpdateBtnClick = true;
            this.IsDraft = false;


            // this.serviceAgreementService.DeleteLock(fdsId, "lockedByApp" , lockToken);
            // fdsServiceAgreementUpdateRequestData.fdsServiceAgreementUpdateModel.fdsAgreementStatus = 4; //001
            this.serviceAgreementService.editServiceAgreement(fdsServiceAgreementUpdateRequestData, fdsId).subscribe((data: ServiceAgreementResponseProfile) => {
              if (data.error && !data.success) {
                var date_1 = new Date(this.fdsServiceAgreementAddModel.expireDate);
                this.fdsServiceAgreementAddModel.expireDate = { "year": date_1.getFullYear(), "month": date_1.getMonth() + 1, "day": date_1.getDate() }

                if (data.error.message) {
                  this.toastr.error(data.error.message);
                } else {
                  this.toastr.error('Error on saving Fee Disclosure Statement.');
                }
              }
              else {
                this.loading = false;
                this.ngxService.stop();
                var fdsServiceId = data.response.id;
                sessionStorage.setItem('serviceAgreementId', data.response.id);
                // var serviceAgrementId =  sessionStorage.getItem('serviceAgreementId');
                if (data?.response?.ValidationMessage?.messageCode == 'MSG001') {
                  this.toastr.error(data.response.ValidationMessage.messageDescription);
                }

                if (data.response.envelopeID != null) {
                  var redirectURL = data.response.returnURL;
                  this.iframeUrl = redirectURL;

                }

                this.serviceAgreementService.setFDStatus(fdsServiceId, 4).subscribe((data) => {
                  if (data.success) {

                    this.toastr.success('Fee Disclosure Statement Update successfully.');
                    // this.event.emit('OK');
                  } else {
                    this.toastr.error('Error on sending Fee Disclosure Statement to client.');
                  }
                },
                  (err: HttpErrorResponse) => {
                    console.log("Error" + err.message);
                  });
                this.loading = false;
                this.ngxService.stop();
                // this.modalRef.hide();
              }
              // this.toastr.success('Fee Disclosure Statement added successfully.');
              // this.event.emit('OK');
            }, err => {
              this.modalRef.hide();
              this.toastr.error('Error on Fee Disclosure Statement.');
            });

          }

          // this.toastr.success('Fee Disclosure Statement added successfully.');
          // this.event.emit('OK');
        } else {
          this.modalRef.hide();
        }
        // this.loading = false;
        // this.ngxService.stop();
      }, err => {
        // this.loading = false;
        // this.ngxService.stop();
      })
    }

  }

  editData(fdsServiceAgreementUpdateRequestData, fdsId) {
    // this.loading = true;
    // this.ngxService.start();
    var lockToken = sessionStorage.getItem('lockToken');
    var lockedByApp = sessionStorage.getItem('lockedByApp');
    //deletelock
    this.deleteLock(fdsId, lockedByApp, lockToken, fdsServiceAgreementUpdateRequestData);


  }

  sendToClients(serviceAgreementData) {
    this.serviceAgreementService.getEnvelopEditUrl(serviceAgreementData.id, serviceAgreementData.envelopeID).subscribe((res: any) => {
      if (res.success) {
        this.iframeUrl = res.response
        sessionStorage.setItem('serviceAgreementId', serviceAgreementData.id)
      } else {
        this.modalRef.hide();
      }
      this.loading = false;
      this.ngxService.stop();
    }, err => {
      this.loading = false;
      this.ngxService.stop();
    })
    this.toastr.success('Fee Disclosure Statement Status Update Successfully.');
    this.event.emit('OK');

    // this.event.emit(this.gridViewStatus);
  }

  addData() {
    this.ngxService.start();
    this.loading = true;
    this.serviceAgreementService.addServiceAgreement(this.serviceAgreementModel, this.companyCode).subscribe((data: ServiceAgreementResponseProfile) => {
      if (data.error && !data.success) {
        var date_1 = new Date(this.fdsServiceAgreementAddModel.expireDate);
        this.fdsServiceAgreementAddModel.expireDate = { "year": date_1.getFullYear(), "month": date_1.getMonth() + 1, "day": date_1.getDate() }
        this.loading = false;
        this.ngxService.stop();
        if (data.error.message) {
          this.toastr.error(data.error.message);
        } else {
          this.toastr.error('Error on saving Fee Disclosure Statement.');
        }
      }
      else {
        this.fdsId = data.response.id;
        sessionStorage.setItem('envelopeID', data.response.envelopeID)
        this.serviceAgreementService.getEnvelopEditUrl(data.response.id, data.response.envelopeID).subscribe((res: any) => {
          if (res.success) {
            this.iframeUrl = res.response
            sessionStorage.setItem('serviceAgreementId', data.response.id)
          } else {
            this.modalRef.hide();
          }
          this.loading = false;
          this.ngxService.stop();
        }, err => {
          this.loading = false;
          this.ngxService.stop();
        })
        this.toastr.success('Fee Disclosure Statement added successfully.');
        this.event.emit('OK');
        // this.event.emit(this.gridViewStatus);
      }
    }, err => {
      this.loading = false;
      this.ngxService.stop();
      this.toastr.error('Error on Fee Disclosure Statement.');
    });
  }


  // addData() {
  //   this.ngxService.start();
  //   this.loading = true;
  //   this.serviceAgreementService.addServiceAgreement(this.serviceAgreementModel, this.companyCode).subscribe((data: ServiceAgreementResponseProfile) => {
  //     if (data.error && !data.success) {
  //       var date_1 = new Date(this.fdsServiceAgreementAddModel.expireDate);
  //       this.fdsServiceAgreementAddModel.expireDate = { "year": date_1.getFullYear(), "month": date_1.getMonth() + 1, "day": date_1.getDate() }
  //       this.loading = false;
  //       this.ngxService.stop();
  //       if (data.error.message) {
  //         this.toastr.error(data.error.message);
  //       } else {
  //         this.toastr.error('Error on saving Fee Disclosure Statement.');
  //       }
  //     }
  //     else {

  //       this.toastr.success('Fee Disclosure Statement added successfully.');
  //       if (data.response.envelopeID != null) {
  //         this.serviceAgreementService.getEnvelopEditUrl(data.response.id, data.response.envelopeID).subscribe((res: any) => {
  //           if (res.success) {
  //             this.iframeUrl = res.response
  //             sessionStorage.setItem('serviceAgreementId', data.response.id)
  //           } else {
  //             this.modalRef.hide();
  //           }
  //           this.loading = false;
  //           this.ngxService.stop();
  //         }, err => {
  //           this.loading = false;
  //           this.ngxService.stop();
  //         });
  //       } else {
  //         this.event.emit('OK');
  //         this.modalRehide(false)
  //       }


  //       this.loading = false;
  //       this.ngxService.stop();
  //       // this.event.emit(this.gridViewStatus);
  //     }
  //   }, err => {
  //     this.loading = false;
  //     this.ngxService.stop();
  //     this.toastr.error('Error on Fee Disclosure Statement.');
  //   });
  // }

  onTemplaChange() {
    this.templateIdRequired = true
  }
  onTemplaClose() {
    this.modalRef.hide();
  }

  onFileChange(event) {
    if (event.target.files[0]) {
      this.fileUploadAddModel.file = <File>event.target.files[0];
      this.documentLabel = event.target.files[0].name;

      console.log(this.documentLabel + "tertfch")
      this.type = event.target.files[0].type;
      this.fileData = event.target;
    }
    else {
      this.documentLabel = "Choose file";
    }
    this.IsvalidDocument = true;
    this.fileRequired = true
  }

  fileUpload() {
    if (this.fileData == "") {
      this.fileRequired = false
    }
    if (this.fdsServiceDocumentsAdd.templateId == "000") {
      this.templateIdRequired = false
    }
    // if(this.fileData != "" && this.fdsServiceDocumentsAdd.templateId != "000"){
    if (this.fileData != "") {
      this.clickedAdd = true;


      //new update
      this.fileUploadAddModel.fileCategory = "4";
      this.fileUploadAddModel.fileType = "1";
      this.fileUploadAddModel.adminView = false;
      this.fileUploadAddModel.uploadType = DocumentUploadType.sharepoint;
      this.fileUploadAddModel.groupCode = this.data.xeppoGroupCode;
      this.fileUploadAddModel.xeppoCode = this.data.xeppoCode;
      this.fileUploadAddModel.companyCode = "DCF01";
      this.fileUploadAddModel.isFds = true;
      this.fileUploadAddModel.documentName = this.documentLabel;
      this.fileUploadAddModel.userId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));
      this.fileUploadAddModel.isPortal = true;

      this.fileUploadService.uploadFileFDS(this.fileUploadAddModel).subscribe((data: FileUploadResponse) => {

        if (data.error && !data.success) {
          this.ngxService.stop();
          this.errors = Utility.GetLocalErrorString(data.error.errorCode);
          //console.log(this.errors);
        }
        else {
          this.fdsServiceDocumentsAdd.documentName = this.fileData.files[0].name;
          let fileSize = this.fileData.files[0].size / 1024 / 1024;
          this.fdsServiceDocumentsAdd.fileSize = fileSize.toFixed(2) + ' MB';

          let templateName = this.templateList.find(x => x.templateId === this.fdsServiceDocumentsAdd.templateId);

          let objectArray = {
            "documentName": this.fdsServiceDocumentsAdd.documentName,
            // "templateName":templateName.name,
            // "templateId":this.fdsServiceDocumentsAdd.templateId,
            "s3Link": data?.response?.s3Key,
            "fileSize": this.fdsServiceDocumentsAdd.fileSize
          };
          this.documentsArray.push(objectArray);

          this.serviceAgreementModel.fdsServiceDocuments = this.documentsArray;
          this.clickedAdd = false;
          this.documentLabel = "Choose file";
          // this.fdsServiceDocumentsAdd.templateId = "000";
          this.fdsServiceDocumentsAdd.document = null;
          this.fileData = "";
          // this.docUploadSuccess = true;
          // this.addData();
        }
      }, err => {
        this.clickedAdd = false;
        this.ngxService.stop();
        this.toastr.error('Document upload error.');
      });
    }
    this.IsvalidDocument = true;
  }

  deleteFile(id, serviceDocument, ifOldFile?) {
    if (ifOldFile) {
      // this.fdsServiceOldDocuments.splice(id,1);
      this.fdsServiceOldDocuments.find(item => item.id == serviceDocument.id).isArchived = true;

    } else {
      this.serviceAgreementModel.fdsServiceDocuments.splice(id, 1);
    }
  }

  getUserGroupList() {
    this.serviceAgreementService.getUserGroupList(this.xeppoGroupCode, this.companyCode).subscribe((data: UserGroupResponse) => {
      this.userGroupList = data.response;
      this.isLoaded2 = true;
    },
      (err: HttpErrorResponse) => {
        //console.log("Error"+ err.message);
      });
  }

  searchClients(searchText) {
    this.searchText = searchText;
    this.isShowRecipient = true;
    this.IsvalidRecipient = true;
    if (searchText.length > 2) {
      let templateName = this.userGroupList?.filter(x => x.name.toLowerCase().includes(searchText.toLowerCase()));
      this.searchResults = templateName;
    }
  }

  addRecipients(recipient) {
    this.isShowRecipient = false;
    let objectArrayRecipients = {
      "xeppoCode": recipient.xeppoCode,
      "xeppoGroupCode": +this.xeppoGroupCode,
      "clientCode": recipient.clientCode,
      "userId": recipient.id,
      "email": recipient.email,
      "isMobileUser": recipient.isMobileUser,
      "name": recipient.name,
      "advisorName": '',
      "advisorEmail": '',
      "isSigned": false,
    };
    // this.fdsRecipients.xeppoCode = recipient.xeppoCode;
    // this.fdsRecipients.xeppoGroupCode = this.xeppoGroupCode;
    // this.fdsRecipients.clientCode = recipient.clientCode;
    // this.fdsRecipients.userId = recipient.id;
    // this.fdsRecipients.email = recipient.email;
    // this.fdsRecipients.isMobileUser = recipient.isMobileUser;
    // this.fdsRecipients.name = recipient.userName;
    var newrecList = this.fdsRecipientsList?.filter(x => !x.isArchived);
    var isPresent = newrecList?.some(function (el) { return el.userId === recipient.id });

    var oldrecList = this.fdsRecipientsOldList?.filter(x => !x.isArchived);
    var isPresentOldRec = oldrecList?.some(function (el) { return el.userId === recipient.id }); //for added validation



    if (recipient && !isPresent && !isPresentOldRec) {
      // const isPresentinOldList = (this.fdsRecipientsOldList && this.fdsRecipientsOldList.length > 0) ? this.fdsRecipientsOldList.some(function(el){ return el.userId === recipient.id}): false;
      // if(!isPresentinOldList) {
      this.fdsRecipientsList.push(objectArrayRecipients);
      this.searchStr = '';
      this.serviceAgreementModel.fdsRecipients = this.fdsRecipientsList;
      this.addAdvisorName();
      this.addAdvisorEmail()
      // } else {
      //   this.toastr.error('Recipient already exists.');
      // }     
    }
    if (isPresent || isPresentOldRec) {
      this.toastr.error('Recipient already exists.');
    }
  }

  deleteRecipient(id, recipientId, isOld?) {
    if (isOld) {
      // this.fdsRecipientsOldList.splice(id,1);
      this.fdsRecipientsOldList.find(item => item.id == recipientId).isArchived = true;
    } else {
      this.fdsRecipientsList.splice(id, 1);
    }
  }

  getDSToken() {
    this.serviceAgreementService.getDSToken().subscribe((data: any) => {
      this.accessToken = data.response.accessToken;
      if (this.accessToken) {
        this.getDSGetTemplates(this.accessToken);
      }
    },
      (err: HttpErrorResponse) => {
        //console.log("Error"+ err.message);
      });
  }

  getDSGetTemplates(accessToken) {
    this.serviceAgreementService.getDSGetTemplates(accessToken).subscribe((data: any) => {
      this.templateList = data.response.templateIds;
      this.isLoaded3 = true;
    },
      (err: HttpErrorResponse) => {
        //console.log("Error"+ err.message);
      });
  }

  expireDate() {
    this.expireDateRequired = true
  }

  // -- Update Table If Newtworth Value Chnaged -- //
  updateRecipientsEmail(object: any, isOldData?) {
    if (!isOldData) {
      // console.log('=vvvvvvvv =', object)

      this.fdsRecipientsList.find(item => item.userId == object.recipientsId).email = object.email;
      // this.fdsRecipientsList.map((todo, i) => {
      //   if (todo.userId == object.recipientsId){
      //      this.fdsRecipientsList[i] = object.email;
      //    }
      //  });
      this.serviceAgreementModel.fdsRecipients = this.fdsRecipientsList;
      // console.log('=vvvvvvvv22 this.fdsRecipientsList=', this.serviceAgreementModel.fdsRecipients)    
    } else {
      this.fdsRecipientsOldList.find(item => item.userId == object.recipientsId).email = object.email;
    }
  }

  updateRecipientsSignApp(object: any, id: any) {
    this.fdsRecipientsOldList?.forEach(element => {
      if (object.recipientsId == element.userId)
        element.isMobileUser = object.isMobileUser;
    });

    this.fdsRecipientsList?.forEach(element => {
      if (object.recipientsId == element.userId)
        element.isMobileUser = object.isMobileUser;
    });
    console.log(this.fdsRecipientsOldList, "----------old---------")
    console.log(this.fdsRecipientsList, "----------new---------")
  }


  addAdvisorEmail() {
    if (this.fdsServiceAgreementAddModel.isAdvisor === 'true' && this.serviceAgreementModel.fdsRecipients && this.serviceAgreementModel.fdsRecipients.length > 0) {
      this.serviceAgreementModel.fdsRecipients[0].advisorEmail = (this.fdsServiceAgreementAddModel || {}).advisorEmail;
      this.serviceAgreementModel.fdsRecipients[0].advisorName = (this.fdsServiceAgreementAddModel || {}).advisorName ? (this.fdsServiceAgreementAddModel || {}).advisorName : '';
    }
  }

  addAdvisorName() {
    if (this.fdsServiceAgreementAddModel.isAdvisor === 'true' && this.serviceAgreementModel.fdsRecipients && this.serviceAgreementModel.fdsRecipients.length > 0) {
      this.serviceAgreementModel.fdsRecipients[0].advisorName = (this.fdsServiceAgreementAddModel || {}).advisorName;
      this.serviceAgreementModel.fdsRecipients[0].advisorEmail = (this.fdsServiceAgreementAddModel || {}).advisorEmail ? (this.fdsServiceAgreementAddModel || {}).advisorEmail : '';
    }
  }

  // -- Update Table If Newtworth Value Chnaged -- //
  updateAdvisorEmail(object: any) {
    // console.log('=vvvvvvvv =', object)
    const advisor = this.fdsRecipientsList.find(item => item.userId == object.recipientsId)
    advisor.advisorEmail = object.email;
    this.serviceAgreementModel.fdsRecipients = this.fdsRecipientsList;
    // console.log('=vvvvvvvv22 this.fdsRecipientsList=', this.serviceAgreementModel.fdsRecipients)
    this.invalid = advisor.advisorEmail && !advisor.advisorName ? true : false;
    const pattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    this.invalidEmail = !pattern.test(advisor.advisorEmail);
  }
  // -- Update Table If Newtworth Value Chnaged -- //
  updateAdvisorName(object: any) {
    // console.log('=vvvvvvvv =', object)
    const advisor = this.fdsRecipientsList.find(item => item.userId == object.recipientsId)
    advisor.advisorName = object.email;
    this.serviceAgreementModel.fdsRecipients = this.fdsRecipientsList;
    // console.log('=vvvvvvvv22 this.fdsRecipientsList=', this.serviceAgreementModel.fdsRecipients)
    this.invalid = !advisor.advisorEmail && advisor.advisorName ? true : false;
  }

  setEmailValidity(event) {
    const pattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    const hasInvalid = this.fdsRecipientsList.filter(a => !pattern.test(a['email']) === true);
    if (hasInvalid.length > 0) {
      this.invalidRecepientEmail = true
    } else {
      this.invalidRecepientEmail = false
    }
    return !pattern.test(event);
  }

  GetDocusignUserByEnvelopeId() {
    // this.ngxService.start();
    // this.loading = true;
    var envilopId = sessionStorage.getItem('envelopeID')
    this.serviceAgreementService.GetDocusignUserByEnvelopeId(envilopId).subscribe((data: any) => {
      this.DocusignUserFullName = data.response.docusignUserFullName;
      // this.loading = false;
      // this.ngxService.stop();
    },
      (err: HttpErrorResponse) => {
        console.log("Error" + err.message);
      });
  }


  GetAllDocuSignUsers() {
    // this.ngxService.start();
    // this.loading = true;
    this.serviceAgreementService.GetAllDocuSignUsers().subscribe((data: any) => {
      this.DocUsersList = data.response;
      console.log("eeeee", this.DocUsersList)
      var defaultUser = this.DocUsersList.filter(x => x.email == "chinthaka@xigenix.net");
      this.fdsServiceAgreementAddModel.impersonatedUserId = defaultUser[0].userId;
      // alert(this.fdsServiceAgreementAddModel.impersonatedUserId)
      // this.loading = false;
      // this.ngxService.stop();
      // this.isLoaded2 = true;
      // this.GetDocusignUserByEnvelopeId();
    },
      (err: HttpErrorResponse) => {
        console.log("Error" + err.message);
      });
  }

  // ngOnDestroy() {
  //   sessionStorage.removeItem('loadedComponent')
  // }
}
