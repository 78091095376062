<app-header></app-header>
<app-sidebar></app-sidebar>

<div class="app-content content">
    <div class="content-wrapper">
          <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">Clients</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Fortress</a> </li>
                            <li class="breadcrumb-item"><a href="/clients">Clients</a> </li>
                            <li class="breadcrumb-item active">Client Profile </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isVerified && client" class="alert bg-red text-center" role="alert">
            This client's Xeppo group is not verified, Please check
        </div>
        <div class="content-body">
            <section id="configuration">
                <div class="row">
                    <div class="col-xl-9 col-md-8 col-sm-12">
                        <ng-container *ngIf="isLoaded1">
                            <section id="stats-subtitle">
                                <div class="row">
                                    <div class="col-xl-4 col-md-4">
                                        <div *ngIf="isLoaded1">
                                            <a href="{{factFinderUrl}}/fact?id={{clientId}}&clientCode={{clientCode}}&name={{webClient.client.firstName + ' ' + webClient.client.lastName }}&xeppoCode={{xeppoCode}}&businessId={{business_id}}&logType=1"
                                                class="ffh" target="_blank">
                                                <div class="card card_profile tabgrad top-link-card">
                                                    <div class="card-content">
                                                        <div class="card-body cleartfix">
                                                            <div class="media-body" style="margin-top: 5px !important;">
                                                                <span class="icon_s text-white">Fact Finder</span><i
                                                                    class="la la-arrow-right float-right text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-4">
                                        <ng-container *ngIf="isLoaded1">
                                            <a href="{{familyTreeUrl}}/dashboard?id={{clientId}}&clientCode={{clientCode}}&xeppoCode={{client.client.xeppoCode}}&firstName={{webClient.client.firstName}}&lastName={{webClient.client.lastName}}&middleName={{webClient.client.middleName}}&dateOfBirth={{webClient.client.dateOfBirth}}&companyCode={{companyCode}}&gender={{webClient.client.gender}}"
                                                target="_blank" class="ffh">
                                                <div class="card card_profile tabgrad top-link-card">
                                                    <div class="card-content">
                                                        <div class="card-body cleartfix">
                                                            <div class="media-body" style="margin-top: 5px !important;">
                                                                <span class="icon_s text-white">Family Tree</span><i
                                                                    class="la la-arrow-right float-right text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </ng-container>
                                    </div>
                                    <div class="col-xl-4 col-md-4">
                                        <div>
                                            <a target="_blank" class="ffh">
                                                <div class="card card_profile tabgrad top-link-card" (click)="netWorthHistory()">
                                                    <div class="card-content">
                                                        <div class="card-body cleartfix">
                                                            <div class="media-body" style="margin-top: 5px !important;">
                                                                <span class="icon_s text-white">Net Worth
                                                                    History</span><i
                                                                    class="la la-arrow-right float-right text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4">
                                        <ng-container *ngIf="xeppoCode">
                                            <a href="{{moneysoftLink}}" target="_blank" class="ffh">
                                                <div class="card card_profile tabgrad top-link-card">
                                                    <div class="card-content">
                                                        <div class="card-body cleartfix">
                                                            <div class="media-body" style="margin-top: 5px !important;">
                                                                <span class="icon_s text-white">Data Feeds</span><i
                                                                    class="la la-arrow-right float-right text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="!xeppoCode">
                                            <a class="ffh">
                                                <div class="card card_profile tabgrad top-link-card">
                                                    <div class="card-content">
                                                        <div class="card-body cleartfix">
                                                            <div class="media-body" style="margin-top: 5px !important;">
                                                                <span class="icon_s text-white">Data Feeds</span><i
                                                                    class="la la-arrow-right float-right text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </ng-container>
                                    </div>
                                    <div class="col-xl-4 col-md-4">
                                        <div *ngIf="!isLoading">
                                            <a target="_blank">
                                                <div class="card card_profile tabgrad top-link-card">
                                                    <div class="card-content">
                                                        <div class="card-body cleartfix">
                                                            <div class="media-body" style="margin-top: 5px !important;">
                                                                <span class="icon_s text-white">Reports</span>
                                                                <i class="la la-arrow-right float-right text-white"
                                                                    data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false" id="downloadReport"></i>

                                                                <div class="dropdown-menu shadow-lg"
                                                                    aria-labelledby="downloadReport">
                                                                    <a class="dropdown-item" target="_blank"
                                                                        (click)="getNWReportSummaryDocument()">Net Worth
                                                                        Summary
                                                                        Report</a>
                                                                    <a class="dropdown-item" target="_blank"
                                                                        (click)="getNetWorthReportDetailDocument()">Net
                                                                        Worth Detail
                                                                        Report</a>
                                                                    <a class="dropdown-item" target="_blank"
                                                                        (click)="getInsuranceReportDocument()">Insurance
                                                                        Report</a>
                                                                    <a class="dropdown-item" target="_blank"
                                                                        (click)="getFTree()">Family Tree</a>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div *ngIf="isLoading">
                                            <div class="card card_profile tabgrad top-link-card">
                                                <div class="card-content">
                                                    <div class="card-body d-flex flex-row justify-content-between">
                                                        <p class="icon_s text-white mb-0">Downloading...</p>

                                                        <div id="loading-bar-spinner" class="spinner">
                                                            <div class="spinner-icon"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4">
                                        <div class="card card_profile tabgrad top-link-card" (click)="fds()">
                                            <div class="card-content">
                                                <div class="card-body cleartfix">
                                                    <div class="media-body" style="margin-top: 5px !important;">
                                                        <span class="icon_s text-white">Documents to Action</span><i
                                                            class="la la-arrow-right float-right text-white cursor-pointer"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4">
                                        <a target="_blank" class="ffh">
                                            <div class="card card_profile tabgrad top-link-card" (click)="realTimeNetworth()">
                                                <div class="card-content">
                                                    <div class="card-body cleartfix">
                                                        <div class="media-body" style="margin-top: 5px !important;">
                                                            <span class="icon_s text-white">Real Time Networth Data</span><i
                                                                class="la la-arrow-right float-right text-white cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div> -->
                                </div>

                                <div class="row">
                                    <div class="col-xl-4 col-md-4">
                                        <div class="card card_icon total-card">
                                            <div class="card-body">
                                                <div class="card-content">
                                                    <div class="media d-flex align-items-center justify-content-between">
                                                        <div class="text-white text-left i_media total-content">
                                                            <ng-container *ngIf="webClient.totalAssets">
                                                                <h3 class="p_font">${{ webClient.totalAssets.toFixed() |
                                                                    number }} </h3>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="webClient.totalAssets == null || webClient.totalAssets == ''">
                                                                <h3 class="p_font">$0 </h3>
                                                            </ng-container>
                                                            <span class="text-muted icon_s">Total Assets</span>
                                                        </div>
                                                        <div class="align-self-center imageContainer"> <img
                                                                src="assets/img/assets.png" alt="assets_icon"> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4">
                                        <div class="card card_icon total-card">
                                            <div class="card-content">
                                                <div class="card-body">
                                                    <div class="media d-flex align-items-center justify-content-between">
                                                        <div class="text-white text-left i_media">
                                                            <ng-container *ngIf="webClient.totalLiabilities">
                                                                <h3 class="p_font">${{
                                                                    webClient.totalLiabilities.toFixed() | number }}
                                                                </h3>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="webClient.totalLiabilities == null || webClient.totalLiabilities == ''">
                                                                <h3 class="p_font">$0 </h3>
                                                            </ng-container>
                                                            <span class="text-muted icon_s">Total Liabilities</span>
                                                        </div>
                                                        <div class="align-self-center imageContainer"> <img
                                                                src="assets/img/liability.png" alt="liability_icon">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4">
                                        <div class="card card_icon total-card">
                                            <div class="card-content">
                                                <div class="card-body">
                                                    <div class="media d-flex align-items-center justify-content-between">
                                                        <div class="text-white text-left i_media">
                                                            <ng-container *ngIf="webClient && webClient.totalNetWorth">
                                                                <h3 class="p_font"> ${{
                                                                    webClient.totalNetWorth.toFixed() | number }} </h3>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="webClient && (webClient.totalNetWorth == null || webClient.totalNetWorth == '')">
                                                                <h3 class="p_font">$0</h3>
                                                            </ng-container>
                                                            <span class="text-muted icon_s">Total Net Worth</span>
                                                        </div>
                                                        <div class="align-self-center imageContainer"> <img
                                                                src="assets/img/coin.png" alt="coin_icon"> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- family tree -->
                                <ng-container *ngIf="client">
                                    <app-family-tree [clientCode]="client.clientCode" [clientId]="clientId">
                                    </app-family-tree>
                                </ng-container>

                            </section>
                        </ng-container>
                        <div class="card">
                            <div class="card-header">
                                <div id="expand-box-header" class="clearfix">
                                    <span style="float: left;">
                                        <h4 class="card-title">Documents</h4> 
                                    </span>
                                    <span style="float: right;">
                                        <span  style="padding-right: 10px;"><button class="btn btn-sm app_document_btn"
                                            (click)="refreshList()" type="button">Refresh List</button></span>
                                        <span ><button class="btn btn-sm app_document_btn"
                                                (click)="openDocumentCreateModal()" type="button">Add
                                                Documents</button></span>
                                    </span>
                                   
                                </div>
                            </div>
                            <div class="card-content">
                                <div class="card-body tabcbody">
                                    <div class="tab-content">
                                        <div class="card">
                                            <div class="card-content collapse show">
                                                <div *ngIf="isLoaded2" class="card-body1 card-dashboard">
                                                    <ng-container *ngIf="userDocumentList && userDocumentList.length >0 ">
                                                            <div  class="example-list document-scroll" >
                                                                <table id="recent-orders"
                                                                    class="table table-striped table-bordered zero-configuration t_dt dataTable no-footer"
                                                                    *ngIf="isLoaded2">
                                                                    <thead>
                                                                        <tr>
                                                                            <th ></th>
                                                                            <th class="border-top-0">Name</th>
                                                                            <th class="border-top-0">View Type</th>
                                                                            <th class="border-top-0">Type</th>
                                                                            <th class="border-top-0">Uploaded Date</th>
                                                                            <th class="border-top-0"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                                                                        <ng-container *ngFor="let userDocument of userDocumentList; let i = index" >
                                                                            <tr cdkDrag>
                                                                                <td class="drag-handle">
                                                                                    <span cdkDragHandle>
                                                                                        <img src="assets/img/icons/drag-dots.svg" alt="Drag" width="16" height="16">
                                                                                      </span>
                                                                                  </td>
                                                                                <td class="text-truncate cursor-pointer" data-toggle="tooltip" title="{{ userDocument.documentName }}">
                                                                                    {{userDocument.documentName | limitTo : 20}}
                                                                                </td>
                                                                                <td class="text-truncate cursor-pointer">
                                                                                    <ng-container
                                                                                        *ngIf="userDocument.adminView==true">
                                                                                        <h6
                                                                                            style="font-size: 13px; color: #2cbad3; ">
                                                                                            Admin only</h6>
                                                                                    </ng-container>
                                                                                </td>
                                                                                <td class="text-truncate cursor-pointer">
                                                                                    <ng-container
                                                                                        *ngIf="userDocument.documentType=='1'">
                                                                                        JPG
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="userDocument.documentType=='2'">
                                                                                        PNG
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="userDocument.documentType=='3'">
                                                                                        PDF
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="userDocument.documentType=='4'">
                                                                                        DOC
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="userDocument.documentType=='5'">
                                                                                        DOCX
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="userDocument.documentType=='6'">
                                                                                        CSV
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="userDocument.documentType=='7'">
                                                                                        XLS
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="userDocument.documentType=='8'">
                                                                                        XLSX
                                                                                    </ng-container>
                                                                                </td>
                                                                                <td class="text-truncate cursor-pointer">{{
                                                                                    formatLocalTime2(userDocument.createdAt) |
                                                                                    date: 'dd/MM/yyyy'}}</td>
                                                                                <td class="text-truncate sa_btns">
                                                                                    <ng-container
                                                                                    *ngIf="userDocument.documentUploadType == 1">
                                                                                    <button type="button"
                                                                                        id="{{userDocument.id}}"
                                                                                        (click)="openConfirmationDialog(template,userDocument.id)"
                                                                                        class="btn btn-icon mr-1 btn_trash"><i
                                                                                            class="la la-trash"></i></button>
                                                                                        </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="userDocument.documentURL != 'test_url' && userDocument.documentUploadType == 1"
                                                                                        class="col-md-6">
                                                                                        <a target="__blank"
                                                                                            (click)="viewDocumentS3(userDocument)"
                                                                                            class="btn btn-icon btn-success mr-1 btntable">
                                                                                            <i class="icon-doc"></i>
                                                                                        </a>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                    *ngIf="userDocument.documentURL != 'test_url' && userDocument.documentUploadType == 2"
                                                                                    class="col-md-6">
                                                                                        <a target="__blank"
                                                                                        (click)="viewDocumentSharepoint(userDocument)"
                                                                                        class="btn btn-icon btn-success mr-1 btntable">
                                                                                        <i class="icon-doc"></i>
                                                                                    </a>
                                                                                </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="userDocument.documentURL == 'test_url'"
                                                                                        class="col-md-6">
                                                                                        <label class="text-center" style="color: orange;" for="">Processing</label>
                                                                                    </ng-container>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="card-content">
                                <div class="card-body tabcbody">
                                    <div class="tab-content">
                                        <div>
                                            <div class="card">
                                                <div class="card-content collapse show">
                                                    <div *ngIf="isLoaded2" class="card-body1 card-dashboard">
                                                        <ng-container
                                                            *ngIf="userDocumentList && userDocumentList.length >0 ">
                                                            <table id="recent-orders"
                                                                class="table table-striped table-bordered zero-configuration t_dt dataTable no-footer"
                                                                *ngIf="isLoaded2" datatable [dtOptions]="dtOptions">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="border-top-0">Name</th>
                                                                        <th class="border-top-0">View Type</th>
                                                                        <th class="border-top-0">Type</th>
                                                                        <th class="border-top-0">Uploaded Date</th>
                                                                        <th class="border-top-0"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr
                                                                        *ngFor="let userDocument of userDocumentList; let i = index">
                                                                        <td class="text-truncate" data-toggle="tooltip"
                                                                            title="{{ userDocument.documentName }}">
                                                                            {{userDocument.documentName | limitTo : 20}}
                                                                        </td>

                                                                        <td class="text-truncate">
                                                                            <ng-container
                                                                                *ngIf="userDocument.adminView==true">
                                                                                <h6
                                                                                    style="font-size: 13px; color: #2cbad3; ">
                                                                                    Admin only</h6>
                                                                            </ng-container>
                                                                        </td>
                                                                        <td class="text-truncate">
                                                                            <ng-container
                                                                                *ngIf="userDocument.documentType=='1'">
                                                                                JPG
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="userDocument.documentType=='2'">
                                                                                PNG
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="userDocument.documentType=='3'">
                                                                                PDF
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="userDocument.documentType=='4'">
                                                                                DOC
                                                                            </ng-container>
                                                                        </td>
                                                                        <td class="text-truncate">{{
                                                                            formatLocalTime2(userDocument.createdAt) |
                                                                            date: 'dd/MM/yyyy'}}</td>

                                                                        <td class="text-truncate sa_btns">
                                                                            <button type="button"
                                                                                id="{{userDocument.id}}"
                                                                                (click)="openConfirmationDialog(template,userDocument.id)"
                                                                                class="btn btn-icon mr-1 btn_trash"><i
                                                                                    class="la la-trash"></i></button>


                                                                            <ng-container
                                                                                *ngIf="userDocument.documentURL"
                                                                                class="col-md-6">
                                                                                <a target="__blank"
                                                                                    (click)="viewDocument(userDocument)"
                                                                                    class="btn btn-icon btn-success mr-1 btntable">
                                                                                    <i class="icon-doc"></i>
                                                                                </a>
                                                                            </ng-container>
                                                                        </td>
                                                                    </tr>


                                                                </tbody>
                                                            </table>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="userDocumentList == null || userDocumentList == ''">
                                                            <img class="mx-auto d-block"
                                                                src="assets/img/empty_state_img.png" alt="avatar">
                                                            <div class="col-12">
                                                                <p class="text-center">Document list is empty</p>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div #serviceAgreement class="card">
                        <app-service-agreement-in-profile [clientCode]="clientCode" [surName]="lastName"
                           [xeppoCode]="xeppoCode" [xeppoGroupCode]="xeppoGroupCode" [keyFirmContacts]="webClient?.keyFirmContacts"></app-service-agreement-in-profile>

                        </div>
                        <div class="card">
                            <div class="card-header">
                                <div id="expand-box-header" class="clearfix">
                                    <span style="float: left;">
                                        <h4 class="card-title">News feed</h4>
                                    </span>
                                </div>
                            </div>
                            <div class="card-content">
                                <div class="card-body tabcbody">
                                    <div class="mt-1 row" *ngIf="isLoaded3 && newsFeedList.length > 0">
                                        <div *ngFor="let newsFeed of newsFeedList; let i = index"
                                            class="col-lg-6 col-xl-4">
                                            <div class="card custom-card-wr mr-2 cursor-pointer"
                                                (click)="viewNewsFeedPopup(newsFeed)">
                                                <ng-container
                                                    *ngIf="newsFeed.coverImageurl=='' || newsFeed.coverImageurl==null ">
                                                    <img class="card-img-top grid_img" src="../assets/img/image@3x.png"
                                                        alt="" />
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.coverImageurl">
                                                    <img class="card-img-top grid_img" src="{{newsFeed.coverImageurl}}"
                                                        alt="" />
                                                </ng-container>

                                                <div class="card-content p-1">
                                                    <div class="d-flex justify-content-between">
                                                        <h3 class="newsfeed-title" data-toggle="tooltip"
                                                            title="{{ newsFeed.title }}">{{newsFeed.title |
                                                            limitTo : 20}}</h3>
                                                        <p class="unread-text text-uppercase">
                                                            <ng-container *ngIf="newsFeed.isRead">READ
                                                            </ng-container>
                                                            <ng-container *ngIf="!newsFeed.isRead">UNREAD
                                                            </ng-container>
                                                        </p>
                                                    </div>

                                                    <p class="mb-0">Audience : {{newsFeed.audience.name}}
                                                    </p>
                                                    <p class="mb-0">Created {{
                                                        formatLocalTime_Z(newsFeed.createdDate) | date:
                                                        'dd/MM/yyyy'}}</p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <ng-container *ngIf="newsFeed.expiredDate">
                                                            <ng-container *ngIf="checkIsExpired(newsFeed.expiredDate)">
                                                                <p class="mb-0 expire">Expires :
                                                                    <ng-container *ngIf="newsFeed.expiredDate">
                                                                        {{formatLocalTime_Z(newsFeed.expiredDate)
                                                                        | date:'dd/MM/yyyy'}}
                                                                    </ng-container>
                                                                </p>
                                                            </ng-container>
                                                            <ng-container *ngIf="!checkIsExpired(newsFeed.expiredDate)">
                                                                <p class="mb-0 expired">Expired :
                                                                    <ng-container *ngIf="newsFeed.expiredDate">
                                                                        {{formatLocalTime_Z(newsFeed.expiredDate)
                                                                        | date:'dd/MM/yyyy'}}
                                                                    </ng-container>
                                                                </p>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="newsFeed.expiredDate == null">
                                                            <p class="mb-0 expiry">Expires : Not set </p>
                                                        </ng-container>
                                                        <ng-container *ngIf="newsFeed.documentType=='1'">

                                                        </ng-container>
                                                        <ng-container *ngIf="newsFeed.documentType=='2'">
                                                            <p class="mb-0 media-txt text-uppercase">Image
                                                            </p>
                                                        </ng-container>
                                                        <ng-container *ngIf="newsFeed.documentType=='3'">
                                                            <p class="mb-0 media-txt text-uppercase">
                                                                Document</p>
                                                        </ng-container>
                                                        <ng-container *ngIf="newsFeed.documentType=='4'">
                                                            <p class="mb-0 media-txt text-uppercase">Video
                                                            </p>
                                                        </ng-container>
                                                        <ng-container *ngIf="newsFeed.documentType=='5'">
                                                            <p class="mb-0 media-txt text-uppercase">Web
                                                                Link </p>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="(isLoaded3 && newsFeedList.length==0)">
                                        <img class="mx-auto d-block soon_imgs" src="../../assets/img/coming_soon.png"
                                            alt="avatar" style="height: 270px;">
                                        <h4 class="text-center" style="color: #808493; margin-bottom: 5px;">News
                                            feed list is empty</h4>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>



                    <!-- Right side section -->
                    <div class="col-xl-3 col-md-4 col-sm-12">
                        <div class="card">
                            <div class="card-header h6 prof_head">About</div>
                            <div class="card-header card-header_profile">
                                <div class="media">
                                    <div class="media-left mr-1">
                                        <div class="avatar_img_border"> <span
                                                class="avatar avatar-md avatar-busy clip_image">
                                                <ng-container *ngIf="client">
                                                    <ng-container *ngIf="client.profileImageUrl !=''">
                                                        <img class="avatar_img_h" [src]="client.profileImageUrl"
                                                            onError="this.src='assets/img/default_user_avatar.png'" />
                                                    </ng-container>
                                                    <ng-container *ngIf="!client.profileImageUrl">
                                                        <img src="assets/img/default_user_avatar.png" alt="avatar">
                                                    </ng-container>
                                                </ng-container>

                                            </span> </div>
                                    </div>
                                    <ng-container *ngIf="isLoaded1">
                                        <div class="media-body" style="margin-top: 4px !important;">
                                            <h5 class="card-title">
                                                <div style="font-weight: 700;" data-toggle="tooltip"
                                                    title=" {{webClient.client.firstName}} {{webClient.client.lastName}}">
                                                    <ng-container *ngIf="webClient.client.firstName">{{
                                                        webClient.client.firstName | limitTo : 15 }} </ng-container>
                                                    <span [ngClass]="webClient.client.firstName?'ss':''">
                                                        <ng-container *ngIf="webClient.client.lastName"> {{
                                                            webClient.client.lastName | limitTo : 15 }}</ng-container>
                                                    </span>
                                                </div>
                                            </h5>
                                            <h6 class="card-sub">
                                                <p _ngcontent-vqe-c1="" style="padding: 0; margin-bottom: 5px;">
                                                    <ng-container *ngIf="client.client.entityType">
                                                        {{client.client.entityType}}</ng-container>
                                                    <ng-container
                                                        *ngIf="client.client.entityType == null || client.client.entityType ==''">
                                                        -</ng-container>
                                                </p>
                                            </h6>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="isLoaded1">
                                    <div class="row outer-border card-sub">
                                        <div class="col-md-5 col-sm-12 width-box-word p-0">Primary Mobile</div>
                                        <div class="col-md-7 col-sm-12 p-0">
                                            <div class="row pr-1">
                                                <div class="col-md-11 text-right p-0 width-box">{{
                                                    client.primaryMobile == null || client.primaryMobile =='' ? '-' :
                                                    client.primaryMobile}}</div>
                                                <div class="col-md-1 p-0">
                                                    <a (click)="openPrimaryMobileEditModal(client)">
                                                        <i class="fa fa-pen prof-detail"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-toggle="tooltip" title="{{client.userName}}">
                                        <div class="card-sub row outer-border">
                                            <div class="col-md-5 col-sm-12 width-box-word p-0">User Name</div>
                                            <div class="col-md-7 col-sm-12 p-0">
                                                <div class="row pr-1">
                                                    <div class="col-md-11 text-right p-0 width-box-word">
                                                        {{(client.userName ==
                                                        null ||
                                                        client.userName =='')? '-' : client.userName | limitTo :
                                                        15}}</div>
                                                    <div class="col-md-1 p-0">
                                                        <a (click)="openUserNameEditModal(client)">
                                                            <i class="fa fa-pen prof-detail"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div id="accordionWrap1" role="tablist" aria-multiselectable="true">
                                <div class="card collapse-icon panel mb-0 box-shadow-0 border-0 collapse-icon_n">
                                    <div *ngIf="client && client.client"
                                        class="border-bottom-lighten-4 profile_border"
                                        [ngClass]="[!client.isActive ? 'card-header-client-disabled' : 'card-header-client-enabled']"
                                        style="padding-top: 13px; padding-bottom: 13px">
                                        <div class="form-check form-check-inline">
                                            <ng-container *ngIf="client.isActive">
                                                <span style="font-size: 13px" class="about_ck_name"> CLIENT ENABLED
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf="!client.isActive">
                                                <span style="font-size: 13px" class="about_ck_name"> CLIENT DISABLED
                                                </span>
                                            </ng-container>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i class="la" (click)="openConfirmationDialog3(template103,client.isActive)"
                                                [ngClass]="[client.isActive ? 'la-toggle-on abouticonon' : 'la-toggle-off abouticonoff']"></i>
                                        </div>
                                    </div>
                                    <div class="card-header border-bottom-lighten-4 profile_border"
                                        style="padding-top: 13px; padding-bottom: 13px">
                                        <ng-container *ngIf="showStaffMembers && client && client.client" >
                                            <div class="form-check form-check-inline">
                                                <span style="font-size: 13px" class="about_ck_name">Staff member client
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <i class="la"
                                                    (click)="openConfirmationDialog1(template100,client.client.isStaffMember)"
                                                    [ngClass]="[client.client.isStaffMember ? 'la-toggle-on abouticonon' : 'la-toggle-off abouticonoff']"></i>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <span style="font-size: 13px" class="about_ck_name">Only visible to
                                                    admins&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <i class="la"
                                                    (click)="openConfirmationDialog2(template101,client.client.isVisibleToStaff)"
                                                    [ngClass]="[!client.client.isVisibleToStaff ? 'la-toggle-on abouticonon' : 'la-toggle-off abouticonoff']"></i>
                                            </div>
                                            <!-- <div class="form-check form-check-inline">
                                                <span style="font-size: 13px" class="about_ck_name">Document Visibility   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <i class="la"
                                                (click)="openConfirmationDialog4(template105,client.client.isVisibleDocument)"
                                                    [ngClass]="[!client.client.isVisibleDocument ? 'la-toggle-on abouticonon' : 'la-toggle-off abouticonoff']"></i>
                                            </div> -->
                                        </ng-container>
                                        <ng-container *ngIf="client && client.client">
                                            <div style="font-size: 13px"  class="form-check form-check-inline row m-0">
                                                <span class="about_ck_name">Realtime Networth   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <i class="la" (click)="openMigrateConfirmDialog(template104,isMigrated)" 
                                                [ngClass]="[isMigrated ? 'la-toggle-on abouticonon' : 'la-toggle-off abouticonoff']"></i>
                                                <!-- <span *ngIf="isMigrated" class="btn btn-round btn-border"  (click)="openRealtimeNetworth();">
                                                    Open App
                                                    <button class="btn toggle-btn p-0">
                                                        <img class="open-icon" src="assets/img/expand-square.svg" alt="Open Fullscreen">
                                                    </button>
                                                </span> -->
                                            </div>
                                        </ng-container>
                                    </div>


                                    <div id="heading11" role="tab"
                                        class="card-header border-bottom-lighten-4 profile_border"> <a
                                            data-toggle="collapse" data-parent="#accordionWrap1" href="#userHistory"
                                            aria-expanded="true" aria-controls="userHistory"
                                            class="h6 blue prof_head">User
                                            History</a> </div>
                                    <div id="userHistory" role="tabpanel" aria-labelledby="heading11"
                                        class="collapse show" aria-expanded="true">
                                        <div class="card-body card_body_prof">
                                            <ng-container *ngIf="isLoaded4 && loginHistoryData.length > 0">
                                                <table
                                                    class="table table-borderless table-sm table-responsive scroll-tbl_about"
                                                    style="padding: 0.6rem 0rem !important;">
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let loginHistory of loginHistoryData; let i = index">
                                                            <td class=""
                                                                style="padding-left: 3px !important; text-align: left !important; width: 40px; padding: 0.6rem 0rem !important;">
                                                                <span class="">
                                                                    <ng-container
                                                                        *ngIf="loginHistory.operatingSystem == 1">
                                                                        <img class="avatar_img_h"
                                                                            src="assets/img/iphone.svg" alt="avatar" />
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="loginHistory.operatingSystem == 2">
                                                                        <img src="assets/img/andr.svg" alt="avatar">
                                                                    </ng-container>
                                                                </span>
                                                            </td>
                                                            <td class=""
                                                                style="padding-left: 3px !important; text-align: left !important; padding: 0.6rem 0rem !important;">
                                                                <span
                                                                    style="font-size: 11px !important;font-weight: 1000;">
                                                                    <ng-container
                                                                        *ngIf="loginHistory.operatingSystem == 1">IOS
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="loginHistory.operatingSystem == 2">
                                                                        Android</ng-container>
                                                                    <ng-container
                                                                        *ngIf="loginHistory.operatingSystem == 3">Web
                                                                    </ng-container>
                                                                    - {{loginHistory.deviceModel}}
                                                                </span>
                                                                <span
                                                                    style="font-size: 11px !important; display: block !important;">
                                                                    <!-- App {{loginHistory.version}} {{loginHistory.loginDate | date:'dd/MM/yyyy h:mm a'}} -->
                                                                    App {{loginHistory.version}}
                                                                    <ng-container
                                                                        *ngIf="loginHistory.lastActiveDate != '0001-01-01T00:00:00'">
                                                                        {{loginHistory.lastActiveDate + 'Z' |
                                                                        date:'dd/MM/yyyy hh:mm a' :
                                                                        loginHistory.timeZone}}
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="loginHistory.lastActiveDate == '0001-01-01T00:00:00'">
                                                                        {{loginHistory.loginDate + 'Z' |
                                                                        date:'dd/MM/yyyy hh:mm a' :
                                                                        loginHistory.timeZone}}
                                                                    </ng-container>
                                                                    <ng-container *ngIf="loginHistory.timeZone">
                                                                        ({{loginHistory.lastActiveDate +
                                                                        loginHistory.timeZone | date:'ZZZZ' :
                                                                        loginHistory.timeZone}})
                                                                    </ng-container>
                                                                </span>
                                                            </td>
                                                        </tr>

                                                        <ng-container *ngIf="loginHistoryDataLength > 3">
                                                            <tr>
                                                                <td colspan="2"
                                                                    style="padding: 0.6rem 0rem !important;">
                                                                    <a class=""
                                                                        style="color:#2cbad3; font-weight:bold; display: block;"
                                                                        (click)="openUserHistoryModal()"> [View
                                                                        More]</a>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                            </ng-container>
                                            <ng-container *ngIf="isLoaded4 && loginHistoryData.length == 0">
                                                <table
                                                    class="table table-borderless table-sm table-responsive scroll-tbl_about">
                                                    <tbody>
                                                        <tr>
                                                            <td>No Data</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div id="heading11" role="tab"
                                        class="card-header border-bottom-lighten-4 profile_border"> <a
                                            data-toggle="collapse" data-parent="#accordionWrap1" href="#accordion11"
                                            aria-expanded="false" aria-controls="accordion11"
                                            class="h6 blue prof_head">Key Firm
                                            Contacts</a> </div>
                                    <div id="accordion11" role="tabpanel" aria-labelledby="heading14"
                                        class="card-collapse collapse" aria-expanded="false" style="height: 0px;">
                                        <ng-container *ngIf="isLoaded1">
                                            <div class="card-body card_body_prof">
                                                <div>
                                                    <ng-container *ngIf="webClient.keyFirmContacts?.length != 0;">
                                                        <ng-container
                                                            *ngFor="let keyFirmContact of webClient.keyFirmContacts; let i = index">
                                                            <ng-container
                                                                *ngIf="keyFirmContact.contactType == 'Partner'">
                                                                <div class="profile_about">
                                                                    <span class="profile_td">Partner :</span>
                                                                    <p>{{keyFirmContact.name}}</p>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="keyFirmContact.contactType.trim() == 'Manager'">
                                                                <div class="profile_about">
                                                                    <span class="profile_td">Manager :</span>
                                                                    <p>{{keyFirmContact.name}}</p>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="keyFirmContact.contactType.trim() == 'Client Adviser'">
                                                                <div class="profile_about">
                                                                    <span class="profile_td">Other Advisers :</span>
                                                                    <p>{{keyFirmContact.name}}</p>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                    <div class="profile_about">
                                                        <span class="profile_td">Xeppo Code :</span>
                                                        <p>
                                                            <ng-container
                                                                *ngIf="client.client.xeppoCode != '' || client.client.xeppoCode != null ">
                                                                {{ client.client.xeppoCode }}</ng-container>
                                                            <ng-container
                                                                *ngIf="client.client.xeppoCode == '' || client.client.xeppoCode == null ">
                                                                -</ng-container>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div id="heading14" role="tab"
                                        class="card-header border-bottom-lighten-4 profile_border"> <a
                                            data-toggle="collapse" data-parent="#accordionWrap1" href="#accordion14"
                                            aria-expanded="false" aria-controls="accordion14"
                                            class="h6 blue collapsed prof_head">Profile</a> </div>
                                    <div id="accordion14" role="tabpanel" aria-labelledby="heading14"
                                        class="card-collapse collapse" aria-expanded="false" style="height: 0px;">
                                        <div class="card-body card_body_prof">
                                            <table *ngIf="isLoaded1"
                                                class="table table-borderless table-sm table-responsive scroll-tbl_about">
                                                <tbody>
                                                    <tr>
                                                        <td class="profile_td pro_tab_about text-truncate">Date Of Birth
                                                        </td>
                                                        <td class="text-left profile_data_about text-truncate">:
                                                            <ng-container *ngIf="client.client.dateOfBirth">{{
                                                                client.client.dateOfBirth | date: 'dd/MM/yyyy' }}
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="client.client.dateOfBirth == null || client.client.dateOfBirth == ''">
                                                                -</ng-container>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="profile_td pro_tab_about">Age</td>
                                                        <td class="text-left profile_data_about">:
                                                            <ng-container
                                                                *ngIf="client.client.age==null || client.client.age == ''">
                                                                -</ng-container>
                                                            <ng-container *ngIf="client.client.age">{{ client.client.age
                                                                }}</ng-container>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="profile_td pro_tab_about">Gender</td>
                                                        <td class="text-left profile_data_about">:
                                                            <ng-container *ngIf="client.client.gender">{{
                                                                client.client.gender }}</ng-container>
                                                            <ng-container
                                                                *ngIf="client.client.gender == null || client.client.gender == ''">
                                                                -</ng-container>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="profile_td pro_tab_about">Smoke</td>
                                                        <td class="text-left profile_data_about">:
                                                            <ng-container *ngIf="client.client.smoker">{{
                                                                client.client.smoker }}</ng-container>
                                                            <ng-container
                                                                *ngIf="client.client.smoker==null || client.client.smoker ==''">
                                                                -</ng-container>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="profile_td pro_tab_about text-truncate">Entity Type
                                                        </td>
                                                        <td class="text-left pro_entity profile_data_about">:
                                                            <ng-container *ngIf="client.client.entityType">{{
                                                                client.client.entityType }}</ng-container>
                                                            <ng-container
                                                                *ngIf="client.client.entityType==null || client.client.entityType==''">
                                                                -</ng-container>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="profile_td pro_tab_about"
                                                            title="{{ client.client.occupation }}">
                                                            Occupation</td>
                                                        <td class="text-left text-truncate profile_data_about"
                                                            title="{{ client.client.occupation }}">:
                                                            <ng-container *ngIf="client.client.occupation">{{
                                                                client.client.occupation }}</ng-container>
                                                            <ng-container
                                                                *ngIf="client.client.occupation==null || client.client.occupation==''">
                                                                -</ng-container>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="profile_td pro_tab_about">Industry</td>
                                                        <td class="text-left text-truncate profile_data_about">:
                                                            <ng-container *ngIf="client.client.industry">{{
                                                                client.client.industry }}</ng-container>
                                                            <ng-container
                                                                *ngIf="client.client.industry==null || client.client.industry ==''">
                                                                -</ng-container>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="profile_td pro_tab_about text-truncate">Client Since
                                                        </td>
                                                        <td class="text-left profile_data_about text-truncate">:
                                                            <ng-container *ngIf="client.client.dateBecameClient">{{
                                                                client.client.dateBecameClient | date: 'dd/MM/yyyy' }}
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="client.client.dateBecameClient==null || client.client.dateBecameClient==''">
                                                                -</ng-container>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td class="profile_td pro_tab_about">Termination Date</td>
                                                        <ng-container *ngIf="client.client.terminationDate">
                                                            <td class="text-left profile_data_about">: {{
                                                                client.client.terminationDate | date: 'dd/MM/yyyy' }}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="client.client.terminationDate == null || client.client.terminationDate == ''">
                                                            <td class="text-left profile_data_about">: -</td>
                                                        </ng-container>
                                                    </tr>
                                                    <tr>
                                                        <td class="profile_td pro_tab_about">ABN</td>
                                                        <ng-container *ngIf="client.client.abn">
                                                            <td class="text-left profile_data_about">: {{
                                                                client.client.abn }}</td>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="client.client.abn == null || client.client.abn == ''">
                                                            <td class="text-left profile_data_about">: -</td>
                                                        </ng-container>
                                                    </tr>
                                                    <tr>
                                                        <td class="profile_td  pro_tab_about">ACN</td>
                                                        <ng-container *ngIf="client.client.acn">
                                                            <td class="text-left profile_data_about">: {{
                                                                client.client.acn }}</td>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="client.client.acn == null || client.client.acn == ''">
                                                            <td class="text-left profile_data_about">: -</td>
                                                        </ng-container>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div id="heading12" role="tab"
                                        class="card-header border-bottom-lighten-4 profile_border"> <a
                                            data-toggle="collapse" data-parent="#accordionWrap1" href="#accordion12"
                                            aria-expanded="false" aria-controls="accordion12"
                                            class="h6 blue collapsed prof_head">Contact Details</a> </div>
                                    <div id="accordion12" role="tabpanel" aria-labelledby="heading12" class="collapse"
                                        aria-expanded="false">
                                        <div class="card-body card_body_prof">

                                            <div *ngIf="isLoaded1">
                                                <div class="profile_about">
                                                    <span class="profile_td">Work Phone :</span>
                                                    <ng-container *ngIf="client.client.primaryMobile">
                                                        <p> {{ client.client.primaryMobile }} </p>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="client.client.primaryMobile == null || client.client.primaryMobile == ''">
                                                        <p> - </p>
                                                    </ng-container>
                                                </div>
                                                <div class="profile_about">
                                                    <span class="profile_td">Mobile Number :</span>
                                                    <ng-container *ngIf="client.client.homeMobile">
                                                        <p> {{ client.client.homeMobile }} </p>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="client.client.homeMobile == null || client.client.homeMobile == ''">
                                                        <p> - </p>
                                                    </ng-container>

                                                </div>
                                                <div class="profile_about">
                                                    <span class="profile_td">Preferred Email :</span>
                                                    <ng-container *ngIf="client.client.preferredEmail">
                                                        <p> {{ client.client.preferredEmail }} </p>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="client.client.preferredEmail == null || client.client.preferredEmail == ''">
                                                        <p> - </p>
                                                    </ng-container>
                                                </div>
                                                <div class="profile_about">
                                                    <span class="profile_td">Home Email :</span>
                                                    <ng-container *ngIf="client.client.homeEmail">
                                                        <p> {{ client.client.homeEmail }} </p>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="client.client.homeEmail == null || client.client.homeEmail == ''">
                                                        <p> - </p>
                                                    </ng-container>
                                                </div>
                                                <div class="profile_about">
                                                    <span class="profile_td">Work Email :</span>
                                                    <ng-container *ngIf="client.client.otherEmail">
                                                        <p> {{ client.client.otherEmail }} </p>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="client.client.otherEmail == null || client.client.otherEmail == ''">
                                                        <p> - </p>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="heading13" role="tab"
                                        class="card-header border-bottom-lighten-4 profile_border"> <a
                                            data-toggle="collapse" data-parent="#accordionWrap1" href="#accordion13"
                                            aria-expanded="false" aria-controls="accordion13"
                                            class="h6 blue collapsed prof_head">Address</a> </div>
                                    <div id="accordion13" role="tabpanel" aria-labelledby="heading13" class="collapse"
                                        aria-expanded="false">
                                        <div class="card-body card_body_prof">

                                            <div *ngIf="isLoaded1">
                                                <div class="profile_about">
                                                    <span class="profile_td">Street Address :</span>
                                                    <p>{{ client.client.streetAddress }}
                                                        {{ client.client.streetAddressSuburb }} {{
                                                        client.client.streetAddressState }} {{
                                                        client.client.streetAddressPostCode }} </p>
                                                </div>
                                                <div class="profile_about">
                                                    <span class="profile_td">Postal Address :</span>
                                                    <p>{{ client.client.postalAddress }} {{
                                                        client.client.postalAddressSuburb }} {{
                                                        client.client.postalAddressState }} {{
                                                        client.client.postalAddressPostCode }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-12 pad_lr">
                            <ng-container>
                                <div class="card overflow-hidden card_profile">
                                    <div class="card-content">
                                        <div class="card-body cleartfix appointment_pn">
                                            <div class="media-body"> <span
                                                    class="text-muted icon_s text-left">Appointments</span>
                                                <button type="button" (click)="openAppointmentModal()"
                                                    class="btn gbtn_gp">Add
                                                    New</button>
                                                <ng-container *ngIf="isLoaded1">
                                                    <ng-container *ngIf="webClient.userNextAppoinment">
                                                        <h2 class="g_h2 h2_mar">{{
                                                            formatLocalTime2(webClient.userNextAppoinment) | date:
                                                            'dd/MM/yyyy'}}</h2>
                                                        <h2 class="g_h2">{{
                                                            formatLocalTime2(webClient.userNextAppoinment) | date:
                                                            'hh:mm a'}}</h2>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="isLoaded1">
                                                    <ng-container
                                                        *ngIf="webClient.userNextAppoinment == null || webClient.userNextAppoinment == ''">
                                                        <h2 class="g_h2_e">No Active <br /> Appointments</h2>
                                                    </ng-container>
                                                </ng-container>

                                            </div>
                                        </div>
                                        <div *ngIf="isLoaded1" id="accordionWrap1" role="tablist"
                                            aria-multiselectable="true">
                                            <div
                                                class="card collapse-icon panel mb-0 box-shadow-0 border-0 collapse-icon_n">
                                                <div id="heading111" role="tab"
                                                    class="card-header border-bottom-lighten-4 profile_border"> <a
                                                        data-toggle="collapse" data-parent="#accordionWrap1"
                                                        href="#accordion111" aria-expanded="false"
                                                        aria-controls="accordion12"
                                                        class="h6 blue collapsed prof_head">All Appointments</a> </div>
                                                <div id="accordion111" role="tabpanel" aria-labelledby="heading111"
                                                    class="collapse" aria-expanded="false">
                                                    <div class="card-content mt-1">
                                                        <div class="table-responsive scroll-tbl_appointment">
                                                            <table id="recent-orders"
                                                                class="table table-hover table-xl mb-0 gp_app_table">
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let appointment of appointmentList; let i = index">
                                                                        <td class="text-truncate">
                                                                            <span class="bord_line appoint_date"> {{
                                                                                formatLocalTime2(appointment.sheduledDate)
                                                                                | date: 'hh:mm a'}} </span>
                                                                            <span class="appoint_time"> {{
                                                                                formatLocalTime2(appointment.sheduledDate)
                                                                                | date: 'dd/MM/yyyy'}} </span>
                                                                        </td>
                                                                        <td class="gp_app_com">

                                                                            <ng-container
                                                                                *ngIf="appointment.appoinmentStatus == '1'">
                                                                                <div class="btn-group float-right">
                                                                                    <button
                                                                                        (click)="openEditModal(appointment)"
                                                                                        type="button"
                                                                                        class="btn btn-icon btn-success mr-1 btntable"><i
                                                                                            class="icon-pencil"></i></button>
                                                                                    <button
                                                                                        (click)="openCallModal(appointment) "
                                                                                        type="button"
                                                                                        class="btn btn-icon btn-success  btntable"><i
                                                                                            class="icon-call-end"></i></button>
                                                                                </div>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="appointment.appoinmentStatus != '1'">
                                                                                <div class="btn-group float-right">
                                                                                    <ng-container
                                                                                        *ngIf="appointment.appoinmentStatus == '3'">
                                                                                        <div
                                                                                            class="badge badge-warning">
                                                                                            Cancelled</div>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="appointment.appoinmentStatus == '4'">
                                                                                        <div
                                                                                            class="badge badge-success">
                                                                                            Completed</div>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="appointment.appoinmentStatus == '5'">
                                                                                        <div
                                                                                            class="badge badge-secondary">
                                                                                            Expired</div>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="appointment.appoinmentStatus == '6'">
                                                                                        <div class="badge badge-danger">
                                                                                            Deleted</div>
                                                                                    </ng-container>
                                                                                </div>
                                                                            </ng-container>

                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="isLoaded1">
                                <div class="card card_profile">
                                    <div class="card-content">
                                        <div class="card-body cleartfix">
                                            <div class="media-body"> <span class="text-muted icon_s">Total
                                                    Properties</span>
                                                <ng-container *ngIf="webClient.client.totalProperties">
                                                    <h1 class="p_font1">${{ webClient.client.totalProperties.toFixed() |
                                                        number }}</h1>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="webClient.client.totalProperties == null || webClient.client.totalProperties == ''">
                                                    <h1 class="p_font1">$0</h1>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center">
        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>


        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline(document_id)">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="documentDelete(document_id)">
                Yes
            </button>
        </div>
    </div>
</ng-template>

<ng-template #template100>
    <div class="modal-body text-center">
        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>


        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline(isStaffMember)">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirmMarkAsPortalClient(isStaffMember)">
                Yes
            </button>
        </div>
    </div>
</ng-template>

<ng-template #template101>
    <div class="modal-body text-center">
        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>


        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline(isVisibleToStaff)">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirmViewOnlyForAdmin(isVisibleToStaff)">
                Yes
            </button>
        </div>
    </div>
</ng-template>

<ng-template #template103>
    <div class="modal-body text-center">
        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>


        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline(isActive)">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirmClientStatus(isActive)">
                Yes
            </button>
        </div>
    </div>
</ng-template>

<ng-template #template104>
    <div class="modal-body text-center">
        <img src="assets/img/warning.png" alt="" class="alert_img">
        <h2>Please Confirm</h2>
        <p>{{message}}</p>
        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="closeMigrate()">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="migrate(isActive, isMigrated)">
                Yes
            </button>
        </div>
    </div>
</ng-template>

<ng-template #template105>
    <div class="modal-body text-center">
        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>


        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline(isVisibleDocument)">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirmViewVisibleDocument(isVisibleDocument)">
                Yes
            </button>
        </div>
    </div>
</ng-template>
<app-footer></app-footer>
<ng-container *ngIf="loaded">
    <ngx-ui-loader></ngx-ui-loader>
</ng-container>
