import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-close-docusign',
  templateUrl: './close-docusign.component.html',
  styleUrls: ['./close-docusign.component.css']
})
export class CloseDocusignComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
      sessionStorage.setItem('closeModal', 'true');
  }

}