<div class="modal-content">
        
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Documents To Action Status</h5>
        <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding: 15px 30px;">

        <form>
            <div class="form-group pt10">
                <label for="formGroupExampleInput" class="titlex">Docusign User</label>
                <p>{{DocusignUserFullName}}</p>
            </div>
            <div class="form-group pt10">
                <label for="formGroupExampleInput" class="titlex">Title</label>
                <p>{{serviceAgreement.title}}</p>
            </div>
            <div class="form-group pt10">
                <label for="exampleFormControlTextarea1" class="titlex">Description </label>
                <p>{{serviceAgreement.description}} </p>
            </div>

            <div class="form-group pt10" *ngIf="serviceAgreement.scheduledDate">
                <label for="lblScheduledDate" class="titlex">Scheduled Date</label>
                <p>{{serviceAgreement.scheduledDate | date: 'dd/MM/yyyy hh:mm a'}}</p>
            </div>

            <div class="form-group pt10">
                <th class="row">
                    <div class="col-6">
                        <label for="formGroupExampleInput" class="titlex">Signature Required Before</label>
                        <p>{{serviceAgreement.expireDate+'Z'  | date:'dd/MM/yyyy'}}</p>
                    </div>
                    <div class="col-6">
                        <label for="formGroupExampleInput" class="titlex">Client Advisor</label>
                        <p *ngIf="advisorData.length>0">{{advisorData[0].advisorName}} ( {{advisorData[0].advisorEmail}} )</p>
                        <p *ngIf="advisorData.length==0">-</p>
                    </div>
                </th>
               
            </div>

            <div class="section-documents">
                <label class="titlex">Documents</label>
                <ng-container *ngIf="serviceAgreementDocument.length>0">
                    <ul>
                        <ng-container *ngFor="let serviceDocument of serviceAgreementDocument; let i = index">
                            <li> 
                                <div class="docs-div"> 
                                    <i class="fa fa-file" aria-hidden="true"></i> 
                                    <span> {{serviceDocument.documentName}} </span> 
                                    <span> {{serviceDocument.templateName}} </span>
                                </div>
                            </li>
                        </ng-container> 
                        </ul>
                </ng-container>
            
            </div>

            <div class="section-documents">
                <label class="titlex">Recipients & Status</label>
                <table width="100%">
                    <ng-container *ngIf="serviceAgreement.fdsRecipients.length>0">
                        <ng-container *ngFor="let recipient of fdsRecipientsList; let i = index">
                            <tr>
                                <td class="word-width" width="25%"> {{recipient.name}} </td>
                                <td class="word-width" width="25%"> {{recipient.email}} </td>
                                <td class="word-width" width="25%"> 
                                    <ng-container *ngIf="recipient.isSigned">
                                        <span><i class="fa fa-check-circle done-color" aria-hidden="true"></i>  Signed ({{recipient.signedDate+'Z' | date:'dd/MM/yyyy'}})</span>  <!-- 12/06/2021 -->
                                    </ng-container>
                                    <ng-container *ngIf="!recipient.isSigned">
                                        <span><i class="fa fa-exclamation-triangle pending-color" aria-hidden="true"></i>  Signature Pending</span> 
                                    </ng-container>
                                </td>
                                <!-- <td *ngIf="recipient?.advisorName" class="word-width" width="25%">( Advisor: {{recipient.advisorName}}, {{recipient.advisorEmail}} )</td>
                                <td *ngIf="!recipient?.advisorName">( Advisor: No advisor )</td> -->
                            </tr>
                        </ng-container>
                    </ng-container>
                </table>
            </div>

        </form>
        <div class="spacer"></div>
    </div>

</div>

<ngx-ui-loader></ngx-ui-loader>
